import React, { useEffect, useState, useRef } from "react";
import ApiLoader from "../../common/ApiLoader";
import MailEditor from "../../modules/email-template/MailEditor";
import Notification from "../../common/Notification";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MediaLibrary from "../../modules/email-template/MediaLibrary";

import {
  AppHeaderComponent,
  ActivityApi,
  DocumentVaultAPI,
  AppButtonComponent,
} from "@unity/components";

export default function TemplateCreate({ route, context }) {
  let history = useHistory();

  const [popup, setPopup] = useState(false);
  const [design, setDesign] = useState({});
  const [loading, setLoading] = useState({ status: false, data: "Loading Template Builder. Please Wait...", firstLoad: true  });
  const [open, setOpen] = useState(false);
  const [docData, setDocData] = useState([]);

  const handleSave = (design, html) => {
    setDesign({ ...design, html: html, json: design });
    setPopup(true);
  };

  const handleCancel = () => {
    setPopup(false);
  };

  const handleSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    const res = await ActivityApi.saveTemplate(design);

    setLoading({ status: true, data: "Save Success!, Please Wait...."});
    setTimeout(() => {
      history.push("/email-templates/index");
    }, 3000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = async () => {
    setOpen(true);
    const res = await DocumentVaultAPI.documentsIndex(1);
    setDocData(res.data);
  };

  const handleSubject = (e) => {
    const newDesign = { ...design };
    newDesign["subject"] = e.target.value;
    setDesign(newDesign);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading({ status: false });
    }, 5000);
  }, []);

  return loading.firstLoad !== undefined && loading.firstLoad ? (
    <>
      <CircularProgress/> <p> Loading Template Builder Resources...</p>
    </>
  ) :
  (
    <div>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="email-templates"
      />
      <ApiLoader status={loading.status} data={loading.data} />
      <div style={{ paddingTop: 130 }}>
        <MediaLibrary
          open={open}
          data={docData}
          onClose={handleClose}
          context={context}
        />
        <Notification
          type="template"
          open={popup}
          design={design}
          setDesign={setDesign}
          cancel={handleCancel}
          accept={handleSubmit}
          title={"Save your template!"}
          description="Please give this template a name and description"
        />
        <MailEditor
          design={design}
          setDesign={setDesign}
          handleSave={handleSave}
        />
      </div>
      <hr />
      <AppButtonComponent
        text="Media Library"
        btnIcon="perm_media"
        style={{
          background: context.theme.sidebar.background,
          color: "white",
        }}
        onClick={() => {
          handleOpen();
        }}
      />
    </div>
  );
}
