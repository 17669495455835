import React from "react";
import { AppHeaderComponent } from "@unity/components";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { categories } from "../../constants";
export default function NegotiationTypeIndex({ route, context }) {
  return (
    <>
      <AppHeaderComponent
        name="activities"
        subPage="index"
        context={context}
        theme={context.theme}
      />
      <div style={{ paddingTop: 120 }}>
        <div className="container">
          <div className="row">
            {categories.negotiation.map((item, key) => {
              return (
                <div className="col-lg-3">
                  <div className="block route-indexer-block">
                    <Link
                      to={`/activities/negotiation/${route.match.params.customer_type}/${route.match.params.customer_id}/${item.path}/index`}
                    >
                      <div>
                        <Icon>{item.icon}</Icon>
                        <p>{item.name}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
