import {
  TicketApi,
  ClientsApi,
  MarketingApi,
  SalesOpApi,
  PurchasingApi,
  CampaignApi,
} from "@unity/components";
import CallForm from "./modules/activities/forms/communication/formTypes/CallForm";
import EmailForm from "./modules/activities/forms/communication/formTypes/EmailForm";
import LetterForm from "./modules/activities/forms/communication/formTypes/LetterForm";
import CustomForm from "./modules/activities/forms/communication/formTypes/CustomForm";
import BulkEmailForm from "./modules/activities/forms/communication/formTypes/BulkEmailForm";
import BulkCallForm from "./modules/activities/forms/communication/formTypes/BulkCallForm";
const associations = [
  {
    id: 1,
    name: "Ticket",
    method: TicketApi.getTicketOpen,
    link: "/tickets/view/",
  },
  { id: 2, name: "Lead", method: ClientsApi.getClients, link: "/leads/view/" },
  {
    id: 3,
    name: "Sales Op",
    method: SalesOpApi.getSalesOpps,
    link: "/sales-op/edit/",
  },
  {
    id: 4,
    name: "Campaign",
    method: MarketingApi.getCampaigns,
    link: "/campaigns/view/",
  },
  {
    id: 5,
    name: "Goods Received",
    method: PurchasingApi.getPurchaseOrders,
    link: "/goods-received/view/",
  },
  {
    id: 6,
    name: "Campaign Responses",
    method: CampaignApi.getResponsesIndexByCampaign,
    link: "/campaigns/responses/",
  },
];

const types = [
  { id: 1, name: "Phone Call" },
  { id: 2, name: "Email" },
  { id: 3, name: "Letter" },
  { id: 4, name: "Custom" },
  { id: 5, name: "Bulk Phone Call" },
  { id: 6, name: "Bulk Email" },
];

const customerTypes = ["clients", "targets", "leads", "suppliers"];

const categories = {
  communication: [
    { name: "All", icon: "people", path: "all" },
    { name: "Phone Call", icon: "people", path: "phone" },
    { name: "Email", icon: "people", path: "email" },
    { name: "Letter", icon: "people", path: "letter" },
    { name: "Custom", icon: "people", path: "custom" },
  ],
  sales: [
    { name: "On Boarding", icon: "people", path: "on-boarding" },
    { name: "After Sales", icon: "people", path: "after-sales" },
  ],

  negotiation: [{ name: "Negotiation", icon: "people", path: "negotiation" }],
};

const formTypes = {
  1: CallForm,
  2: EmailForm,
  3: LetterForm,
  4: CustomForm,
  5: BulkCallForm,
  6: BulkEmailForm,
};

const typeTags = [
  { id: 1, name: "Sales Op" },
  { id: 2, name: "Lead" },
  { id: 3, name: "Campaign" },
];

export { associations, customerTypes, categories, formTypes, types, typeTags };
