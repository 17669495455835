import React, { useEffect, useState } from "react";
import PageHeader from "../../common/PageHeader";
import { Checkbox, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
//import { saveScripts } from "../../api/scripts";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

import {
  AppHeaderComponent,
  AppButtonComponent,
  ActivityApi,
} from "@unity/components";
export default function CallScriptEdit(props) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ status: false, data: false });
  let history = useHistory();

  const onSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    const x = await ActivityApi.updateCallScript(
      data,
      props.route.match.params.id
    );

    setLoading({ status: true, data: "Save Success!, Please Wait...." });
    setTimeout(() => {
      history.push("/call-scripts/index");
    }, 2000);
  };

  const getData = async () => {
    const res = await ActivityApi.getCallScriptSingle(
      props.route.match.params.id
    );
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <div>
        <ApiLoader status={loading.status} data={loading.data} />
        <AppHeaderComponent
          saveBtn={onSubmit}
          context={props.context}
          theme={props.context.theme}
          name="call-scripts"
          subpage="edit"
        />
        <div style={{ paddingTop: 120 }}>
          <TextField
            label="Script Name"
            name="name"
            value={data.name}
            onChange={(e) =>
              setData({ ...data, [e.target.name]: e.target.value })
            }
            fullWidth
          />
          <div style={{ marginTop: 20 }}>
            <TextField
              label="Script"
              name="script"
              value={data.script}
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
              fullWidth
              multiline
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}
