import React, { useState } from "react";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListAddTable from "./ListAddTable";
import ListDeleteTable from "./ListDeleteTable";

export default function ListCreatorForm(props) {
  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>List Creator Info</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>List Name</InputLabel>
              <TextField
                name="name"
                value={props.data.name ? props.data.name : ""}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Association Category</InputLabel>
              <Select
                name="associate_category"
                fullWidth
                value={
                  props.data.associate_category
                    ? props.data.associate_category
                    : ""
                }
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.associations &&
                  props.associations.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Association</InputLabel>
              {props.data.associate_category ? (
                <>
                  {!props.associationLoading ? (
                    <Select
                      name="associate_id"
                      fullWidth
                      value={
                        props.data.associate_id ? props.data.associate_id : ""
                      }
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                    >
                      {props.associationData &&
                        props.associationData.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.id}>
                              {item.name && item.name}
                              {item.ticket_name && item.ticket_name}
                              {item.campaign_name && item.campaign_name}
                              {item.sales_opp_overview &&
                                item.sales_opp_overview}
                              {item.reference && item.reference}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  ) : (
                    <p>Loading Please Wait....</p>
                  )}
                </>
              ) : (
                <Select value={0} fullWidth>
                  <MenuItem value={0}>
                    Please select an association category
                  </MenuItem>
                </Select>
              )}
            </div>
            {props.typeButton && (
              <div className="form-group col-lg-4">
                <InputLabel>Tag Name</InputLabel>
                <TextField
                  name="tag"
                  value={props.tagName}
                  onChange={(e) => props.setTagName(e.target.value)}
                  fullWidth={true}
                />
                <Button onClick={props.handleTagSub}>Submit</Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Filter Criteria</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>Town</InputLabel>

              <TextField
                name="town"
                disabled={true}
                value={props.data.criteria.town ? props.data.criteria.town : ""}
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Post Code</InputLabel>
              <TextField
                name="postcode"
                disabled={true}
                value={
                  props.data.criteria.postcode
                    ? props.data.criteria.postcode
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Industry</InputLabel>
              <TextField
                name="industry"
                disabled={true}
                value={
                  props.data.criteria.industry
                    ? props.data.criteria.industry
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel>Created By </InputLabel>
              <TextField
                name="created_by"
                disabled={true}
                value={
                  props.data.criteria.created_by
                    ? props.data.criteria.created_by
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Tag </InputLabel>
              <TextField
                name="tag"
                disabled={true}
                value={props.data.criteria.tag ? props.data.criteria.tag : ""}
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Region </InputLabel>
              <TextField
                name="region"
                disabled={true}
                value={
                  props.data.criteria.region ? props.data.criteria.region : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Number of Employees </InputLabel>
              <TextField
                name="no_of_employees"
                disabled={true}
                value={
                  props.data.criteria.no_of_employees
                    ? props.data.criteria.no_of_employees
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Sic Code </InputLabel>
              <TextField
                name="sic_code"
                disabled={true}
                value={
                  props.data.criteria.sic_code
                    ? props.data.criteria.sic_code
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel> Created At Date </InputLabel>
              <TextField
                name="created_at_start"
                type="datetime-local"
                value={
                  props.data.criteria.created_at_start
                    ? props.data.criteria.created_at_start
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel>Ended At Date</InputLabel>
              <TextField
                name="created_at_end"
                type="datetime-local"
                value={
                  props.data.criteria.created_at_end
                    ? props.data.criteria.created_at_end
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Updated At Start Date</InputLabel>
              <TextField
                name="updated_at_start"
                type="datetime-local"
                value={
                  props.data.criteria.updated_at_start
                    ? props.data.criteria.updated_at_start
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>

            <div className="form-group col-lg-4">
              <InputLabel>Updated At End Date</InputLabel>
              <TextField
                name="updated_at_end"
                type="datetime-local"
                value={
                  props.data.criteria.updated_at_end
                    ? props.data.criteria.updated_at_end
                    : ""
                }
                onChange={(e) =>
                  props.handleCriteriaChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              />
            </div>
          </div>
        </div>
        <div>
          <button onClick={props.criteriaSearch}>Reload</button>
          <button onClick={props.handlePopUp}>Add Data</button>
          <h4>Results found: {props.criteriaResult}</h4>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Add / Remove From List </h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-6">
              <InputLabel>Company's To Add</InputLabel>

              <ListAddTable
                disabled={props.type === "view"}
                data={props.data}
                addOptions={props.addOptions}
                handleFuzzySearchAdd={props.handleFuzzySearchAdd}
                handleFuzzySearch={props.handleFuzzySearch}
                handleAutoComplete={props.handleAutoComplete}
                handleFuzzySearchDelete={props.handleFuzzySearchDelete}
                handleTypedValue={props.handleTypedValue}
                typedValue={props.typedValue}
                added={props.added}
                type={props.type}
              />
            </div>
            <div className="form-group col-lg-6">
              <InputLabel>Company's To Remove</InputLabel>
              <ListDeleteTable
                disabled={props.type === "view"}
                data={props.data}
                removeOptions={props.removeOptions}
                handleFuzzySearchRemove={props.handleFuzzySearchRemove}
                handleFuzzySearch={props.handleFuzzySearch}
                handleAutoComplete={props.handleAutoComplete}
                handleFuzzySearchDelete={props.handleFuzzySearchDelete}
                handleTypedValue={props.handleTypedValue}
                type={props.type}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
