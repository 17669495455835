import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";

export default function EventScheduler({ type, personnel, handleChange, data }) {

    return (
        <div className="block">
            <div className="container-fluid">
                <div className="form-row">
                    <div className="col-lg-3">
                        <InputLabel>Start Date / Time</InputLabel>
                        <TextField type="datetime-local" name="StartTime" value={data.StartTime}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </div>

                    <div className="col-lg-3">
                        <InputLabel>End Date / Time</InputLabel>
                        <TextField type="datetime-local" name="EndTime" value={data.EndTime}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </div>

                    <div className="col-lg-3">
                        <InputLabel>Choose a Calendar</InputLabel>
                        <Select name="UserRef" fullWidth value={data.UserRef} onChange={(e) => handleChange(e.target.name, e.target.value)}>
                            {personnel && personnel.map((item, key) => {
                                return <MenuItem key={key} value={item.uuid}>{item.name}</MenuItem>
                            })}
                        </Select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-lg-12">
                        <InputLabel>Subject</InputLabel>
                        <TextField type="text" name="Subject" value={data.Subject}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            fullWidth
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-lg-12">
                        <InputLabel>Description</InputLabel>
                        <TextField type="text" name="Description" value={data.Description}
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}