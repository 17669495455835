import React, { useState, useEffect } from "react";

import { AppHeaderComponent, LeadsApi, PersonnelApi, ActivityApi, CalendarApi } from "@unity/components";
import NegotiationActivityForm from "../../modules/activities/forms/negotiate/NegotiationActivityForm";
import GlobalActivitySettingWrapper from "../GlobalActivitySettingWrapper";
import ApiLoader from "../../common/ApiLoader";
import { useHistory } from "react-router-dom";

export default function NegotiateActivityCreate({ route, context }) {
  const [data, setData] = useState({ activity: {} });
  const [eventData, setEventData] = useState(false)

  const [personnel, setPersonnel] = useState([]);
  const [loading, setLoading] = useState({ status: false, data: false });
  let history = useHistory();

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data)
  }


  const handleSave = async () => {
    data['tag'] = route.location.state.tag;
    data['customer_id'] = route.match.params.customer_id;
    var event = false;
    if (eventData) {
      event = await CalendarApi.storeEvent(eventData);
    }
    data['event_id'] = event ? event.data.Id : null;

    setLoading({ status: true, data: "Saving Record, Please Wait..." });
    await ActivityApi.saveActivity("negotiate", data);
    setLoading({ status: true, data: "Save Success, Please Wait" });
    setTimeout(() => {
      history.goBack();
    }, 3000);

  }

  const handleChange = (name, value) => {
    const newData = { ...data };
    newData['activity'][name] = value;
    setData(newData)
  };

  useEffect(() => {
    getPersonnelData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent saveBtn={handleSave} context={context} theme={context.theme} name="activities" subpage="negotiation" subpage2="create" />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <GlobalActivitySettingWrapper eventData={eventData} setEventData={setEventData} personnel={personnel}>
            <NegotiationActivityForm
              data={data}
              handleChange={handleChange}
              type="create"
            />
          </GlobalActivitySettingWrapper>

        </div>
      </>

    );
  } else {
    return <Loading />;
  }
}