import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "../services/AuthContext";
import NegotiateActivityView from "../pages/negotiate/view";
import NegotiateActivityCreate from "../pages/negotiate/create";
import NegotiateActivityEdit from "../pages/negotiate/edit";
import NegotiateActivityIndex from "../pages/negotiate/activityIndex";
import CustomerIndexer from "../pages/common/customerIndex";
import NegotiationTypeIndex from "../pages/negotiate/typeIndex";
import CustomerIndex from "../pages/common";
export default function NegotiationActivityRoutes(props) {
  const context = props.context;

  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>

                    <Route
                      path="/activities/negotiation/index"
                      exact
                      component={(route) => (
                        <CustomerIndexer
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/negotiation/:customer_type/index"
                      exact
                      component={(route) => (
                        <CustomerIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/negotiation/:customer_type/:customer_id/index"
                      exact
                      component={(route) => (
                        <NegotiationTypeIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/negotiation/:customer_type/:customer_id/:activity_type/index"
                      exact
                      component={(route) => (
                        <NegotiateActivityIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/negotiation/:customer_type/:customer_id/:activity_type/view/:activity_id"
                      exact
                      component={(route) => (
                        <NegotiateActivityView
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/negotiation/:customer_type/:customer_id/:activity_type/edit/:activity_id"
                      exact
                      component={(route) => (
                        <NegotiateActivityEdit
                          route={route}
                          context={context}
                          history={history}
                        />
                      )}
                    />

                    <Route
                      path="/activities/negotiation/:customer_id/create"
                      exact
                      component={(route) => (
                        <NegotiateActivityCreate
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
