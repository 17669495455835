import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ListCreatorIndex from "../pages/list-creator";
import ListCreatorCreate from "../pages/list-creator/create";
import ListCreatorView from "../pages/list-creator/view";
import ListCreatorEdit from "../pages/list-creator/edit";
import ListDuplication from "../pages/list-creator/duplicate";
import AuthContext from "../services/AuthContext";
export default function ListCreatorRoutes({ context, route, history }) {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <section className="route-section">
                <Switch location={location}>
                  <Route
                    path="/list-creator/create"
                    exact
                    component={(route) => (
                      <ListCreatorCreate
                        context={context}
                        history={history}
                        route={route}
                      />
                    )}
                  />
                  <Route
                    path="/list-creator/index"
                    exact
                    component={(route) => (
                      <ListCreatorIndex
                        context={context}
                        history={history}
                        route={route}
                      />
                    )}
                  />

                  <Route
                    path="/list-creator/index/:id"
                    exact
                    component={(route) => (
                      <ListCreatorIndex
                        context={context}
                        history={history}
                        route={route}
                      />
                    )}
                  />

                  <Route
                    path="/list-creator/edit/:id"
                    exact
                    component={(route) => (
                      <ListCreatorEdit
                        context={context}
                        history={history}
                        route={route}
                      />
                    )}
                  />
                  <Route
                    path="/list-creator/view/:id"
                    exact
                    component={(route) => (
                      <ListCreatorView
                        context={context}
                        history={history}
                        route={route}
                      />
                    )}
                  />

                  <Route
                    path="/list-creator/duplicate/:id"
                    exact
                    component={(route) => (
                      <ListCreatorEdit
                        context={context}
                        history={history}
                        route={route}
                      />
                    )}
                  />
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}
