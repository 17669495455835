import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";

import {
  AppHeaderComponent,
  ActivityApi,
  AppButtonComponent,
} from "@unity/components";

export default function TemplateView(props) {
  const [data, setData] = useState(false);

  const getTemplateData = async () => {
    const template = await ActivityApi.getTemplateSingle(
      props.route.match.params.id
    );
    setData(template.data);
  };

  useEffect(() => {
    getTemplateData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          name={"email-templates"}
          context={props.context}
          theme={props.context.theme}
        />

        <div
          style={{ paddingTop: 130 }}
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
