import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppButtonComponent } from "@unity/components";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { TextField, Select, MenuItem, makeStyles } from "@material-ui/core";

export default function PersonnelSelection(props) {
  const useStyles = makeStyles({
    table: {
      minWidth: 70,
    },
  });

  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Company</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.criteria.remove &&
              props.data.criteria.remove.map((item, key) => (
                <TableRow key={key}>
                  <TableCell>
                    <Autocomplete
                      disabled={props.type === "view"}
                      value={item}
                      options={props.removeOptions}
                      getOptionLabel={(options) =>
                        options.customer_name || options.company_name || ""
                      }
                      onChange={(e, val) =>
                        props.handleAutoComplete(val, key, "remove")
                      }
                      onInputChange={(e, val) =>
                        props.handleTypedValue(val, "remove")
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Remove Company" />
                      )}
                      clearOnBlur={false}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {props.type != "view" && (
          <AppButtonComponent
            disabled={props.type === "view"}
            btnIcon="add"
            text="add new"
            style={{ background: "blue", color: "white" }}
            onClick={() => props.handleFuzzySearchRemove()}
          />
        )}
        {props.type != "view" && (
          <AppButtonComponent
            btnIcon="delete"
            text="Delete"
            style={{ background: "blue", color: "white" }}
            onClick={() => props.handleFuzzySearchDelete("remove")}
          />
        )}
      </TableContainer>
    </>
  );
}
