import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import {
  ActivityApi,
  AppHeaderComponent,
  AppButtonComponent,
} from "@unity/components";
import FilterTable from "../../common/FilterTable";

export default function NegotiateActivityIndex({ route, context }) {
  const [data, setData] = useState(false);

  const columns = [
    { name: "tag", label: "Activity Tag" },
    { name: "negotiate_element", label: "Negotiate Element" },
    { name: "created_by", label: "Created By" },
    { name: "date_created", label: "Date Created" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <>
              <Link
                to={{
                  pathname: `/activities/negotiation/${route.match.params.customer_type}/${route.match.params.customer_id}/${route.match.params.activity_type}/view/${data[dataIndex].id}`,
                }}
              >
                <AppButtonComponent
                  btnIcon="visibility"
                  text="view"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginRight: 15,
                  }}
                />
              </Link>

              <Link
                to={{
                  pathname: `/activities/negotiation/${route.match.params.customer_type}/${route.match.params.customer_id}/${route.match.params.activity_type}/edit/${data[dataIndex].id}`,
                }}
              >
                <AppButtonComponent
                  btnIcon="edit"
                  text="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
            </>
          );
        },
      },
    },
  ];

  const getData = async () => {
    const res = await ActivityApi.activitiesIndex(
      "negotiation",
      route.match.params.customer_id,
      1
    );
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  var select;

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
  };

  const [tabValue, setTabValue] = useState(0);

  const tabs = [
    { icon: "check", label: "Open Leads" },
    { icon: "close", label: "Lost Leads" },
    { icon: "refresh", label: "Converted Leads" },
    { icon: "delete", label: "Deleted Leads" },
    { icon: "settings", label: "Module Settings" },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (data) {
    return (
      <>
        <AppHeaderComponent
          addNew={() => (
            <Link
              to={{
                pathname: `/activities/negotiation/${route.match.params.customer_id}/create`,
                state: { tag: "Sales Op" },
              }}
            >
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="negotiation"
          subpage2="index"
        />
        <div style={{ paddingTop: 160 }}>
          <FilterTable
            columns={columns}
            data={data}
            options={options}
            title={"Displaying all"}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
