import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  PersonnelApi,
  ActivityApi,
} from "@unity/components";
import CommunicationActivityForm from "../../modules/activities/forms/communication/CommunicationActivityForm";
import { associations } from "../../constants";

export default function ActivitiesView({ route, context }) {
  const [data, setData] = useState({
    //associated_lead: 1,
    //minutes_spent: 12,
    //type: 1,
    //activity: { name: "Phone Call Test", notes: "Hello World" },
  });
  const [associationData, setAssociationData] = useState([]);
  const [associationLoading, setAssociationLoading] = useState(false);
  const [personnel, setPersonnel] = useState([]);

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const getActivityData = async () => {
    const res = await ActivityApi.getActivitySingle(
      route.match.params.activity_id
    );
    const dataid = res.data.association_category;

    setData(res.data);

    //Get the id
    //Call the method for the id
    //Save the result into the state

    if (data) {
      //Set loading to true
      setAssociationLoading(true);
      //Find the object that matches the Id from the association array
      const assoicationObj = associations.find((e) => e.id == dataid);
      //Get the method attached to the found object and call it
      const method = assoicationObj.method;
      const assData = await method("leads");
      //Set the state to the returned data
      setAssociationData(assData.data);

      setAssociationLoading(false);
    }
  };

  const handleSave = async () => {};

  const handleChange = (name, value) => {};

  const handleActivityChange = (name, value) => {};

  const handleUpload = (e) => {};

  useEffect(() => {
    getPersonnelData();
    getActivityData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="view"
        />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <CommunicationActivityForm
            data={data}
            handleChange={handleChange}
            handleUpload={handleUpload}
            handleActivityChange={handleActivityChange}
            type="view"
            associations={associations}
            associationLoading={associationLoading}
            associationData={associationData}
            personnel={personnel}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
