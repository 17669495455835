import React from "react";
import { AppHeaderComponent } from "@unity/components";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { customerTypes } from "../../constants";
export default function CustomerIndexer({ route, context }) {
  const prevRoute = route.location.pathname;
  const routeParts = prevRoute.replace("index", "");

  return (
    <>
      <AppHeaderComponent
        name="activities"
        subPage="index"
        context={context}
        theme={context.theme}
      />

      <div style={{ paddingTop: 120 }}>
        <div className="container">
          <div className="row">
            {customerTypes.map((item, key) => {
              return (
                <div className="col-lg-3" key={key}>
                  <div className="block route-indexer-block">
                    <Link to={`${routeParts}${item}/index`}>
                      <div>
                        <Icon>people</Icon>
                        <p>{item}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
