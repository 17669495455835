import React from "react";
import { InputLabel, MenuItem, TextField, Select } from "@material-ui/core";

export default function CallForm(props) {
    return (
        <div>
            <div className="form-row">
                <div className="col-lg-4">
                    <InputLabel >Activity Name</InputLabel>
                    <TextField name="name" onChange={(e) => props.handleActivityChange(e.target.name, e.target.value)} value={props.data.activity.name} fullWidth />
                </div>
                <div className="col-lg-4">
                    <InputLabel>Phone Number</InputLabel>
                    <TextField name="contact_phone" onChange={(e) => props.handleActivityChange(e.target.name, e.target.value)} value={props.data.activity.contact_phone} fullWidth />
                </div>
            </div>

            <div className="form-row">
                <div className="col-lg-12">
                    <InputLabel >Call Notes</InputLabel>
                    <TextField multiline rows={12} name="notes" onChange={(e) => props.handleActivityChange(e.target.name, e.target.value)} value={props.data.activity.notes} fullWidth />
                </div>
            </div>
        </div>
    )
}