import React, { useEffect, useState } from "react";
import { AppHeaderComponent, CalendarApi, PersonnelApi, ActivityApi } from "@unity/components";
import Loading from "../../../../common/Loading";
import OnBoardingForm from "../../forms/sales/OnBoardingForm";
import Categories from "../../ActivityCategories";
import GlobalActivitySettingWrapper from "../../../../pages/GlobalActivitySettingWrapper";
import { useHistory } from "react-router-dom";
import ApiLoader from "../../../../common/ApiLoader";


export default function AfterSalesActivityPage({ type, route, context }) {
  const [data, setData] = useState(type === "create" ? { activity: {} } : false);
  const [personnel, setPersonnel] = useState(false);
  const [categories] = useState(Categories);
  const [eventData, setEventData] = useState(false)
  const [loading, setLoading] = useState({ status: false, data: false })
  let history = useHistory();

  const getData = async () => {
    if (type !== "create") {
      const res = await ActivityApi.getActivitySingle(route.match.params.activity_id)
      setData(res.data)
    }
  };

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const handleSave = async () => {
    if (type === "create") {
      data['tag'] = route.location.state.tag;
      data['customer_id'] = route.match.params.customer_id;
      var event = data.event_id;
      if (data.event_id) {
        await CalendarApi.updateEvent(data.event_id, eventData)
      } else if (!data.event_id && eventData) {
        event = await CalendarApi.storeEvent(eventData);
      }
      data['event_id'] = event ? event.data.id : null;
      setLoading({ status: true, data: "Saving Record, Please Wait..." });
      await ActivityApi.saveActivity("sale", data);
      setLoading({ status: true, data: "Save Success, Please Wait" });
      setTimeout(() => {
        history.goBack();
      }, 3000)
    } else {

      setLoading({ status: true, data: "Updating Record, Please Wait..." });
      var event = data.event_id;
      if (data.event_id) {
        await CalendarApi.updateEvent(data.event_id, eventData)
      } else if (!data.event_id && eventData) {
        event = await CalendarApi.storeEvent(eventData);
      }
      data['event_id'] = event ? event.data.id : null;
      await ActivityApi.updateActivity("sale", data, data.id);
      setLoading({ status: true, data: "Update Success, Please Wait" });
      setTimeout(() => {
        setLoading({ status: false })
      }, 3000)
    }
  };

  const handleChange = (name, value) => {
    const newData = { ...data };
    newData['activity'][name] = value;
    setData(newData);
  };

  const handleRootChange = (name, value) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);
  };

  const handleUpload = (e) => { };

  const handleAddRecord = () => {
    const newData = { ...data };
    if (!newData.users) newData['activity']["users"] = [];
    newData['activity']["users"].push({});
    setData(newData);
  };

  const handleSelectUser = (id, key) => {
    const newData = { ...data };
    newData['activity']["users"][key] = id;
    setData(newData);
  };

  const handleDeleteUser = (key) => {
    const newData = { ...data };
    newData['activity']["users"].splice(key, 1);
    setData(newData);
  };


  useEffect(() => {
    getData();
    getPersonnelData();
  }, []);

  if (data && personnel) {
    return (
      <>

        <ApiLoader status={loading.status} data={loading.data} />

        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="sales"
          subpage2="view"
        />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <GlobalActivitySettingWrapper personnel={personnel} eventData={eventData} setEventData={setEventData}>
            <OnBoardingForm
              data={data}
              handleChange={handleChange}
              handleUpload={handleUpload}
              title={"After Sales Activity"}
              personnel={personnel}
              categories={categories}
              handleAddRecord={handleAddRecord}
              handleSelectUser={handleSelectUser}
              handleDeleteUser={handleDeleteUser}
              handleRootChange={handleRootChange}
            />
          </GlobalActivitySettingWrapper>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
