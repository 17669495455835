import React from "react";
import AfterSalesActivityPage from "../../modules/activities/pages/sales/AfterSalesActivityPage";
import OnBoardingActivityPage from "../../modules/activities/pages/sales/OnBoardingActivityPage";

export default function SalesActivityView({ route, context }) {

  const forms = {
    "on-boarding": OnBoardingActivityPage,
    "after-sales": AfterSalesActivityPage,
  }

  const RenderForm = forms[route.match.params.activity_type];

  return (
    <>
      <RenderForm type="view" route={route} context={context} />
    </>

  );
}