import React, { useEffect, useState } from "react";
import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import { ActivityApi } from "@unity/components";

export default function EmailForm(props) {
  const [size, setSize] = useState(0);
  const [tags, setTags] = useState([]);
  const calculateEmailLength = () => {
    let size = encodeURI(
      props.data.activity.to +
        props.data.activity.subject +
        props.data.activity.body
    ).length;

    setSize(size);
  };

  const getTags = async () => {
    const res = await ActivityApi.getEmailTags();

    setTags(res.data);
  };

  useEffect(() => {
    getTags()
  }, []);
  return (
    <div>
      <div className="form-row">
        <div className="col-lg-4">
          <InputLabel>Activity Name</InputLabel>
          <TextField
            name="name"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.name}
            fullWidth
          />
        </div>
        <div className="col-lg-4">
          <InputLabel>Email Subject</InputLabel>
          <TextField
            name="subject"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.subject}
            fullWidth
          />
        </div>
        <div className="col-lg-4">
          <InputLabel>Email Tag</InputLabel>
          <Select
            name="tag"
            value={props.data.activity.tag ? props.data.activity.tag : "Generic"}
            onChange={(e) =>
              props.handleActivityChange( e.target.name, e.target.value )
            }
            fullWidth
          >
            {tags && 
              tags.map((tag, key) => {
                return (
                  <MenuItem key={key} value={tag.name}>
                    {tag.name}
                  </MenuItem>
                );
              })
            }
          </Select>
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-8">
          <InputLabel>Email Recipient</InputLabel>
          <TextField
            name="to"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.to}
            fullWidth
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-12">
          <InputLabel>Email Body</InputLabel>
          <TextField
            name="body"
            onChange={(e) => {
              props.handleActivityChange(e.target.name, e.target.value),
                calculateEmailLength();
            }}
            value={props.data.activity.body ? props.data.activity.body : ""}
            fullWidth
            multiline
            rows={12}
          />
        </div>
        <div>
          Word Count: {size} / 2000
          {size >= 2000 && (
            <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
              Email is over the word count
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
