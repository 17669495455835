import React, { useEffect, useState, useRef } from "react";
import ApiLoader from "../../common/ApiLoader";
import MailEditor from "../../modules/email-template/MailEditor";
import Notification from "../../common/Notification";
import MediaLibrary from "../../modules/email-template/MediaLibrary";
import {
  AppHeaderComponent,
  ActivityApi,
  AppButtonComponent,
  DocumentVaultAPI
} from "@unity/components";
import Loading from "../../common/Loading";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function TemplateEdit(props) {
  const [popup, setPopup] = useState(false);
  const [design, setDesign] = useState(false);
  const [loading, setLoading] = useState({ status: true, data: "Loading Template Builder. Please Wait..." });
  const [open, setOpen] = useState(false);
  const [docData, setDocData] = useState([]);
  const [ templateName, setTemplateName ] = useState("");
  const [ templateDesc, setTemplateDesc ] = useState("");
  const emailEditorRef = useRef(null);
  let history = useHistory();

  const handleSave = (design, html) => {
    setDesign({ ...design, html: html, json: design });
    setPopup(true);
  };

  const handleSubject = (e) => {
    const newDesign = { ...design };
    newDesign["subject"] = e.target.value;
    setDesign(newDesign);
  };

  const handleCancel = () => {
    setPopup(false);
  };

  const handleSubmit = async () => {
    if( design.template_name === undefined ) 
    {
      design.template_name = templateName;
    }

    if( design.template_description === undefined )
    {
      design.template_description = templateDesc;
    }

    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });

    if (props.route.location.state.duplicate) {
      const res = await ActivityApi.saveTemplate(design);
    } else {
      const res = await ActivityApi.updateTemplate(
        design,
        props.route.match.params.id
      );
    }
    setLoading({ status: true, data: "Save Success!, Please Wait...." });
    setTimeout(() => {
      history.push("/email-templates/index");
    }, 3000);
  };

  const handleSetup = async () => {
    const res = await ActivityApi.getTemplateSingle(
      props.route.match.params.id
    );
    await setDesign(res.data);
    await setTemplateName(res.data.template_name);
    await setTemplateDesc(res.data.template_description);
    
    setLoading({status: false});
  };

  const handleOpen = async () => {
    setOpen(true);
    const res = await DocumentVaultAPI.documentsIndex(1);
    setDocData(res.data);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    handleSetup();
  }, []);
  if (design) {
    return (
      <>
        <AppHeaderComponent
          name={"email-templates"}
          subpage={"edit"}
          context={props.context}
          theme={props.context.theme}
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <MediaLibrary
          open={open}
          data={docData}
          onClose={handleClose}
          context={props.context}
        />
        <Notification
          type="template"
          open={popup}
          design={design}
          setDesign={setDesign}
          cancel={handleCancel}
          accept={handleSubmit}
          title={"Save your template!"}
          description="Please give this template a name and description"
        />
        <div style={{ paddingTop: 150 }}>
          <MailEditor
            design={design}
            setDesign={setDesign}
            emailEditorRef={emailEditorRef}
            jsonTemplate={design.json}
            type={"edit"}
            handleSave={handleSave}
          />
        </div>
        <hr/>
        <AppButtonComponent
          text="Media Library"
          btnIcon="perm_media"
          style={{
            background: props.context.theme.sidebar.background,
            color: "white"
          }}
          onClick={() => {handleOpen();}}
          />
      </>
    );
  } else {
    return <Loading />;
  }
}
