import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  DynamicListApi,
  ClientsApi,
  TargetsApi,
} from "@unity/components";
import ApiLoader from "../../common/ApiLoader";
import { useHistory } from "react-router-dom";
import ListCreatorForm from "../../modules/activities/forms/list-creator/listCreatorForm";
import { associations } from "../../constants";
import { Button } from "@material-ui/core";
import Loading from "../../common/Loading";
import ListCreatorPopUp from "../../modules/activities/forms/list-creator/ListCreatorPopUp";
import useDebounce from "../../common/CustomHooks";
export default function ListCreatorEdit({ route, context }) {
  let history = useHistory();
  const [data, setData] = useState(false);

  const [loading, setLoading] = useState({ status: false });
  const [associationData, setAssociationData] = useState([]);
  const [associationLoading, setAssociationLoading] = useState(false);
  const [endPoint, setEndPoint] = useState("targets");
  const [criteriaResult, setCriteriaResult] = useState([]);
  const [addOptions, setAddOptions] = useState([]);
  const [removeOptions, setRemoveOptions] = useState([]);
  const [popUp, setPopUp] = useState(false);
  const [criteriaText, setCriteriaText] = useState("");

  const [added, setAdded] = useState([]);
  const [typedValue, setTypedValue] = useDebounce("", 1000);

  const [typeButton, setTypeButton] = useState(false);
  const [tagName, setTagName] = useState("");

  const handleCancel = () => {
    setPopUp(false);
  };

  const handlePopUp = () => {
    setPopUp(true);
  };

  const handlePopupData = (popupData) => {
    const newData = { ...data };
    //newData.criteria["created_by_name"] = [];
    console.log("DATA", newData);
    newData.criteria[popupData.name].push(popupData.value);
    setData(newData);
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Loading Please Wait..." });
    //setting the list_type
    data["list_type"] = endPoint;

    let saveStatus;

    if (route.location.state.duplicate) {
      const res = await DynamicListApi.saveDynamicList(data);
      saveStatus = res;
    } else {
      const res = await DynamicListApi.updateDynamicList(
        data,
        route.match.params.id
      );
      saveStatus = res;
    }

    if (saveStatus.status == 200) {
      setLoading({ status: true, data: "Save Success Please Wait" });
      setTimeout(() => {
        history.push("/list-creator/index");
      }, 4000);
    } else {
      setLoading({ status: false, data: "" });
      alert("Error Saving");
    }
  };

  const getListSingle = async () => {
    const res = await DynamicListApi.getDynamicListSingle(
      route.match.params.id
    );
    setData(res.data);
    setEndPoint(res.data.list_type);
    if (res.data.list_type === "clients") {
      const res2 = await ClientsApi.getFilterCount(
        res.data.list_type,
        res.data
      );
      setCriteriaResult(res2.data);
    } else if (res.data.list_type === "targets") {
      const newObj = { criteria: res.data.criteria };
      const res2 = await TargetsApi.filterSetCountTargets(newObj);

      setCriteriaResult(res2.data);
    }
  };

  const criteriaSearch = async () => {
    if (endPoint === "clients") {
      const res = await ClientsApi.getFilterCount(endPoint, data);
      setCriteriaResult(res.data);
    } else if (endPoint === "targets") {
      const res = await TargetsApi.filterSetCountTargets(data);
      setCriteriaResult(res.data);
    }
  };

  const handleFuzzySearchAdd = async () => {
    const newData = { ...data };
    newData.criteria.add.push({ customer_id: null });
    setData(newData);
    if (endPoint === "clients") {
      const add = await ClientsApi.fuzzyFilterNotSearch(endPoint, data);
      setAddOptions(add.data);
    } else if (endPoint === "targets") {
      const add = await TargetsApi.filterSetNotSearch(data);
      setAddOptions(add.data);
    }
  };

  const handleFuzzySearchRemove = async () => {
    const newData = { ...data };
    newData.criteria.remove.push({ customer_id: null });
    setData(newData);
    if (endPoint === "clients") {
      const remove = await ClientsApi.fuzzyFilterSearch(endPoint, data);
      setRemoveOptions(remove.data);
    } else if (endPoint === "targets") {
      const remove = await TargetsApi.filterSetSearchTargets(data);
      setRemoveOptions(remove.data);
    }
  };

  const handleChange = async (name, value) => {
    setData({ ...data, [name]: value });

    if (name === "association_category") {
      setAssociationLoading(true);
      const associationObj = associations.find((e) => e.id === value);
      const method = associationObj.method;
      const res = await method(value === 2 && "leads");
      setAssociationData(res.data);
      setAssociationLoading(false);
    }
  };

  const handleAutoComplete = (val, key, type) => {
    const newData = { ...data };

    if (endPoint === "clients") {
      newData["criteria"][type][key]["customer_id"] = val.customer_id;
      newData["criteria"][type][key]["customer_name"] = val.customer_name;
    } else if (endPoint === "targets") {
      newData["criteria"][type][key]["id"] = val.id;
      newData["criteria"][type][key]["company_name"] = val.company_name;
    }
    data["search"] = "";
    setData(newData);
  };
  const handleCriteriaChange = (name, value) => {
    const tempData = { ...data };
    tempData["criteria"][name] = value;

    setData(tempData);
  };

  const updateRemoveOptions = async (value) => {
    data["search"] = value;

    if (endPoint === "clients") {
      const remove = await ClientsApi.fuzzyFilterSearch(endPoint, data);
      setRemoveOptions(remove.data);
    } else if (endPoint === "targets") {
      const remove = await TargetsApi.filterSetSearchTargets(data);
      setRemoveOptions(remove.data);
    }
  };

  const updateAddOptions = async (value) => {
    data["search"] = value;
    if (endPoint === "clients") {
      const add = await ClientsApi.fuzzyFilterNotSearch(endPoint, data);
      setAddOptions(add.data);
    } else if (endPoint === "targets") {
      const add = await TargetsApi.filterSetNotSearch(data);
      setAddOptions(add.data);
    }
  };

  const handleTypedValue = (value, type) => {
    setTypedValue(value);
    if (type === "add") {
      updateAddOptions(value);
    }
    if (type === "remove") {
      updateRemoveOptions(value);
    }
  };

  const handleTagSub = async () => {
    console.log("PRESSING THE BUTTON ");

    const newObj = {
      criteria: data.criteria,
      tag: tagName,
    };

    const res = await TargetsApi.setTagType(newObj);

    alert("Tags have been set");
  };

  const handleSetTags = () => {
    typeButton ? setTypeButton(false) : setTypeButton(true);
  };

  const SetTagsButton = () => {
    return (
      <>
        <Button
          variant="outlinded"
          onClick={() => {
            handleSetTags();
          }}
        >
          Set Tags
        </Button>
      </>
    );
  };

  const EndPointButtons = () => {
    return (
      <>
        <Button
          variant="outlined"
          onClick={() => {
            setEndPoint("clients");
          }}
        >
          Clients
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setEndPoint("targets");
          }}
        >
          Targets
        </Button>
        <h2> Searching: {endPoint} </h2>
      </>
    );
  };

  //Delay for the fuzzy Search
  const delay = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  const handleFuzzySearchDelete = (name, key) => {
    const newData = { ...data };
    // newData.criteria[name].splice(0, 1);

    newData.criteria[name].pop();
    setData(newData);
  };

  const handleDelete = (popupData) => {
    const newData = { ...data };

    console.log(newData);
    newData.criteria[popupData.name].pop();
    setData(newData);
  };

  useEffect(() => {
    //updateAddOptions();
    //updateRemoveOptions();
  }, []);

  useEffect(() => {
    getListSingle();
  }, []);

  if (data) {
    return (
      <>
        <ListCreatorPopUp
          open={popUp}
          handleCancel={handleCancel}
          handlePopupData={handlePopupData}
          handleDelete={handleDelete}
          searchText={setCriteriaText}
          endPointList={endPoint}
        />
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="list-creator"
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <EndPointButtons />
          <SetTagsButton />
          <ListCreatorForm
            data={data}
            handleChange={handleChange}
            handleCriteriaChange={handleCriteriaChange}
            criteriaSearch={criteriaSearch}
            handlePopUp={handlePopUp}
            associations={associations}
            associationLoading={associationLoading}
            associationData={associationData}
            criteriaResult={criteriaResult}
            addOptions={addOptions}
            removeOptions={removeOptions}
            handleFuzzySearchAdd={handleFuzzySearchAdd}
            handleFuzzySearchRemove={handleFuzzySearchRemove}
            handleAutoComplete={handleAutoComplete}
            handleFuzzySearchDelete={handleFuzzySearchDelete}
            added={added}
            handleTypedValue={handleTypedValue}
            typedValue={typedValue}
            listType={endPoint}
            typeButton={typeButton}
            tagName={tagName}
            setTagName={setTagName}
            handleTagSub={handleTagSub}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
