import React, { useState, useEffect } from "react";
import OnBoardingActivityPage from "../../modules/activities/pages/sales/OnBoardingActivityPage";
import AfterSalesActivityPage from "../../modules/activities/pages/sales/AfterSalesActivityPage";

export default function SalesActivityCreate({ route, context }) {
  const forms = {
    "on-boarding": OnBoardingActivityPage,
    "after-sales": AfterSalesActivityPage,
  };

  const RenderForm = forms[route.match.params.activity_type];

  return (
    <>
      <RenderForm type="create" route={route} context={context} />
    </>
  );
}
