import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  ActivityApi,
} from "@unity/components";
import { Link } from "react-router-dom";
import { Icon } from "@material-ui/core";
import "./RouteIndexer.css";
import { associations, types } from "../constants";
import FilterTable from "../common/FilterTable";
export default function RouteIndexer({ route, context, moduleAccess }) {
  const [data, setData] = useState();

  const nameIcon = {
    communication: "record_voice_over_icon",
    negotiation: "face",
    sales: "money",
    "go-integrator": "phone",
  };
  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const columns = [
    {
      name: "tag",
      label: "Activity Tag",
      options: {
        filterList: route.location.state
          ? [route.location.state && route.location.state.filter]
          : false,
      },
    },
    {
      name: "name",
      label: "Activity Name",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <span>{data[dataIndex].activity.name}</span>;
        },
      },
    },
    {
      name: "type",
      label: "Activity Type",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const typeObject = types.find((e) => e.id == data[dataIndex].type);
          return typeObject.name;
        },
      },
    },
    {
      name: "association_category",
      label: "Assoc Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const category_id = data[dataIndex].association_category;
          if (category_id) {
            const data = associations.find((e) => e.id == category_id);
            return data.name;
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "association_link",
      label: "Assoc Link",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const category_id = data[dataIndex].association_category;
          if (category_id) {
            const el = associations.find((e) => e.id == category_id);
            return (
              <Link to={`${el.link}${data[dataIndex].association}`}>
                {data[dataIndex].association}
              </Link>
            );
          } else {
            return "";
          }
        },
      },
    },

    { name: "created_by", label: "Created By" },
    { name: "deadline_date", label: "Deadline Date" },
  ];

  const options = {};

  const getData = async () => {
    const obj = {};
    const res = await ActivityApi.getActivitysByDeadline(obj);

    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AppHeaderComponent
        name="activities"
        subPage="index"
        context={context}
        theme={context.theme}
      />
      <div style={{ paddingTop: 120 }}>
        <div>
          <FilterTable
            columns={columns}
            data={data}
            options={options}
            title={"Activites Due Today"}
          />
        </div>

        <div className="container">
          <div className="d-flex justify-content-center">
            {Object.keys(moduleAccess).map((item, key) => {
              return (
                <div className="col-lg-2">
                  <div className="block route-indexer-block">
                    <Link to={`/activities/${item}/index`}>
                      <div>
                        <Icon>{nameIcon[item]}</Icon>
                        <p>{capitalizeFirstLetter(item)}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-lg-2">
              <div className="block route-indexer-block">
                <Link to="/activities/emailTag/index">
                  <div>
                    <Icon>label</Icon>
                    <p>Email Tags</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
