import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  PersonnelApi,
  ActivityApi,
  CalendarApi,
} from "@unity/components";
import CommunicationActivityForm from "../../modules/activities/forms/communication/CommunicationActivityForm";
import { associations } from "../../constants";
import GlobalActivitySettingWrapper from "../GlobalActivitySettingWrapper";
import { useHistory } from "react-router-dom";
import ApiLoader from "../../common/ApiLoader";
import Loading from "../../common/Loading";

export default function ActivitiesEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [eventData, setEventData] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [associationData, setAssociationData] = useState([]);
  const [associationLoading, setAssociationLoading] = useState(true);
  const [loading, setLoading] = useState({ status: false, data: false });

  const types = {
    1: "phone",
    2: "email",
    3: "letter",
    4: "custom",
    5: "bulkPhone",
    6: "bulkEmail",
  };
  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const getData = async () => {
    const res = await ActivityApi.getActivitySingle(
      route.match.params.activity_id
    );
    const associationObj = associations.find(
      (e) => e.id == res.data.association_category
    );
    if (associationObj) {
      const method = associationObj.method;
      const res2 = await method(res.data.association_category == 2 && "leads");
      setAssociationData(res2.data);
    }
    setAssociationLoading(false);
    setData(res.data);
  };

  const handleChange = async (name, value) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);

    if (name === "association_category") {
      setAssociationLoading(true);
      const associationObj = associations.find((e) => e.id === value);
      const method = associationObj.method;
      const res = await method(value === 2 && "leads");
      setAssociationData(res.data);
      setAssociationLoading(false);
    }
  };

  const handleActivityChange = (name, value) => {
    const newData = { ...data };
    newData["activity"][name] = value;
    setData(newData);
  };

  const handleSave = async () => {
    //important
    setLoading({ status: true, data: "Updating Record, Please Wait..." });
    var event = data.event_id;

    if (data.event_id) {
      await CalendarApi.updateEvent(data.event_id, eventData);
    } else if (!data.event_id && eventData) {
      event = await CalendarApi.storeEvent(eventData);
      data["event_id"] = event.data.Id;
    }

    data["activity"] = { ...data["activity"] };
    data['name'] = data['activity']['name'];
    const res = await ActivityApi.updateActivity(
      types[data.type],
      data,
      data.id
    );
    setLoading({ status: true, data: "Update Success, Please Wait" });
    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };

  const handleUpload = (e) => {};

  useEffect(() => {
    getData();
    getPersonnelData();
  }, []);

  if (data) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />

        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="edit"
        />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <GlobalActivitySettingWrapper
            event_id={data.event_id}
            eventData={eventData}
            setEventData={setEventData}
            personnel={personnel}
          >
            <CommunicationActivityForm
              data={data}
              handleChange={handleChange}
              handleUpload={handleUpload}
              handleActivityChange={handleActivityChange}
              associations={associations}
              associationLoading={associationLoading}
              associationData={associationData}
              personnel={personnel}
              type="edit"
            />
          </GlobalActivitySettingWrapper>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
