import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  ActivityApi,
  PersonnelApi,
  CalendarApi,
  DynamicListApi,
} from "@unity/components";
import CommunicationActivityForm from "../../modules/activities/forms/communication/CommunicationActivityForm";
import { associations } from "../../constants";
import GlobalActivitySettingWrapper from "../GlobalActivitySettingWrapper";
import { useHistory } from "react-router-dom";
import ApiLoader from "../../common/ApiLoader";

export default function ActivitiesCreate({ route, context, createCustomerId }) {
  const [data, setData] = useState({ activity: {} });
  const [eventData, setEventData] = useState({});
  const [associationData, setAssociationData] = useState([]);
  const [associationLoading, setAssociationLoading] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [loading, setLoading] = useState({ status: false, data: false });

  const [listIndex, setListIndex] = useState([]);
  let history = useHistory();
  const types = {
    1: "phone",
    2: "email",
    3: "letter",
    4: "custom",
    5: "bulkPhone",
    6: "bulkEmail",
  };

  let createTypeTag;
  if (route.location.state.tag === "indexPage") {
    createTypeTag = true;
  } else createTypeTag = false;

  const handleSave = async () => {
    let confirmed = true;
    if(data.type == 6)
    {
      confirmed = confirm("Notice! You are about to send/schedule a bulk email. Please confirm your list numbers and template");
    }
    
    if( confirmed )
    {
      var event = false;
      if (Object.entries(eventData).length != 0) {
        event = await CalendarApi.storeEvent(eventData);
      }
  
      //important
      if (route.location.state.tag != "indexPage") {
        data["tag"] = route.location.state.tag;
      }
      if (createCustomerId) {
        data["customer_id"] = route.location.state.customer_id;
      } else {
        data["customer_id"] = route.match.params.customer_id;
      }
      data["event_id"] = event ? event.data.Id : null;
  
      const tempData = { ...data };
      tempData['name'] = data['activity']['name'];
  
      setData(tempData);
  
      setLoading({ status: true, data: "Saving Record, Please Wait..." });
      const x = await ActivityApi.saveActivity(types[data.type], data);
      console.log("saved data", x);
      setLoading({ status: true, data: "Save Success, Please Wait" });
      setTimeout(() => {
        history.goBack();
      }, 3000);
    }
  };

  const handleChange = async (name, value) => {
    const newData = { ...data };
    newData[name] = value;
    setData(newData);

    if (name === "association_category") {
      setAssociationLoading(true);
      const associationObj = associations.find((e) => e.id === value);
      const method = associationObj.method;
      if (associationObj.name === "Campaign Responses") {
        const res2 = await method(route.match.params.customer_id);
        setAssociationData(res2.data);
        setAssociationLoading(false);
      } else {
        const res = await method(value === 2 && "leads");
        setAssociationData(res.data);
        setAssociationLoading(false);
      }
    }
  };

  const getDynamicListData = async (id) => {
    const listRes = await DynamicListApi.getDynamicListSingle(id);
    setListIndex(listRes.data);
    return listRes.data.list_type;
  };

  const handleActivityChange = async (name, value) => {
    const newData = { ...data };
    newData["activity"][name] = value;
    setData(newData);
  };

  const handleDynamicListChange = async (name, value) => {
    const newData = { ...data };
    newData["activity"][name] = value;
    if (data.type === 6) {
      const x = await getDynamicListData(value);

      newData.activity["list_type"] = x;
    }
    setData(newData);
  };

  const handleUpload = (e) => {};

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  useEffect(() => {
    getPersonnelData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="create"
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <GlobalActivitySettingWrapper
            eventData={eventData}
            setEventData={setEventData}
            personnel={personnel}
          >
            <CommunicationActivityForm
              data={data}
              handleChange={handleChange}
              handleUpload={handleUpload}
              handleActivityChange={handleActivityChange}
              handleDynamicListChange={handleDynamicListChange}
              associations={associations}
              associationLoading={associationLoading}
              associationData={associationData}
              personnel={personnel}
              type="create"
              createTypeTag={createTypeTag}
            />
          </GlobalActivitySettingWrapper>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
