module.exports = [
    { id: 1, name: "Training" },
    { id: 2, name: "Training Materials Production" },
    { id: 3, name: "Importing / Transforming Data" },
    { id: 4, name: "Setup New Users" },
    { id: 5, name: "Snagging List" },
    { id: 6, name: "Discussion About Next Steps" },
    { id: 7, name: "Demo" },
    { id: 8, name: "Up / Cross Sell" },
    { id: 9, name: "Other" },
]