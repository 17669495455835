import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import {
  ActivityApi,
  ClientsApi,
  DynamicListApi,
  CampaignApi,
} from "@unity/components";
import AppButton from "../../../common/AppButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CallBoxClients({
  open,
  data,
  title,
  handleCancel,
  stage,
  setStage,
  targets,
  active,
  number,
  script,
  handleSelectedClient,
  nextCall,
  goBack,
  currentData,
  callActions,
  setCurrentData,
  setActive,
  listType,
  setNumber,
  handleSaveAsNewActivity,
  selectedClient,
  setSelectedClient,
}) {
  const [completeAlert, setCompleteAlert] = useState(false);

  console.log();

  const getData = async () => {};
  //

  const handleUnsub = async () => {
    const res = await ClientsApi.setPhonePrefFalse(selectedClient.id);

    alert("Unsubscribed");
  };

  const handleView = () => {
    window.open(`/${listType}/view/${targets[active].customer_id}`, "_blank");

    //'${listType}/view/${targets[active].customer_id}'
  };

  const saveCall = async () => {
    const savedData = {
      model: "Contact-Service/Customer",
      id: targets[active].customer_id,
      action: "Customer Was Called",
      description: `Notes: ${currentData.notes}, Action: ${currentData.action}`,
    };
    const res = await ActivityApi.saveLog(savedData);

    if (active + 1 <= targets.length - 1) {
      setActive(active + 1);
      setNumber(false);
      setStage(1);
      setCurrentData({});
      setSelectedClient({});
      var notesField = document.getElementById("notesField");
      notesField.value = "";
      return;
    }

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
  };

  const convertResponse = async () => {
    const obj = {
      campaign_id: data.association,
      client_id: targets[active].customer_id,
      subject: "Call List Response",
      body: currentData.notes,
      type: "Phone",
    };

    const res = CampaignApi.saveResponse(obj);

    const savedData = {
      model: "Contact-Service/Customer",
      id: targets[active].customer_id,
      action: "Converted To a Response",
      description: `Notes: ${currentData.notes}, Action: ${currentData.action}`,
    };
    const res2 = await ActivityApi.saveLog(savedData);

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
    nextCall();
  };

  const hideCompleteAlert = () => {
    setCompleteAlert(false);

    handleCancel(active, data);
  };

  return (
    <div style={{ width: "800px!important" }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCancel(active, data)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ maxWidth: "800px!important", minWidth: "200px!important" }}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        {targets.length > 0 ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-2">
                {active + 1 + " / " + targets.length}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{ textAlign: "center", padding: 20 }}
                  >
                    <>
                      <img src={require("../../../assets/unity-group.png")} />
                      <p>{targets[active].name}</p>
                    </>
                    {targets[active].active_site_contacts.length > 0 ? (
                      <Select
                        //value={selectedClient}
                        onChange={(e) => handleSelectedClient(e.target.value)}
                      >
                        {targets[active].active_site_contacts.map(
                          (item, key) => {
                            return (
                              <MenuItem key={key} value={item}>
                                {item.first_name + item.surname}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    ) : (
                      <b>
                        This client has no contacts or was not assigned any
                        during list creation.
                      </b>
                    )}
                  </div>
                  <div className="col-lg-12">
                    {stage === 1 && (
                      <>
                        {targets[active].active_site_contacts &&
                          targets[active].active_site_contacts.length > 0 && (
                            <a href={`dial:${number}`}>
                              <AppButton
                                btnIcon="phone"
                                text="Start Call"
                                style={{ background: "green", color: "white" }}
                                onClick={() => setStage(2)}
                              />
                            </a>
                          )}
                        <AppButton
                          btnIcon="arrow_back"
                          text="Go Back"
                          style={{
                            background: "blue",
                            color: "white",
                            float: "right",
                            marginRight: 5,
                          }}
                          onClick={() => goBack()}
                        />
                        <AppButton
                          btnIcon="skip_next"
                          text="Skip Call"
                          style={{
                            background: "red",
                            color: "white",
                            float: "right",
                          }}
                          onClick={() => nextCall()}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <TextField
                      id="notesField"
                      label="Notes"
                      name="notes"
                      value={targets[active].notes ? targets[active].notes : ""}
                      fullWidth
                      onChange={(e) => {
                        setCurrentData({
                          ...currentData,
                          [e.target.name]: e.target.value,
                        });
                        targets[active].notes = e.target.value;
                      }}
                      multiline
                    />
                  </div>
                  {stage === 2 && (
                    <>
                      <div className="row">
                        <div className="col-lg-12">
                          <InputLabel
                            style={{
                              marginLeft: 13,
                              paddingTop: 10,
                              width: "100%",
                            }}
                          >
                            Call Result
                          </InputLabel>

                          <Select
                            name="action"
                            fullWidth
                            onChange={(e) => {
                              setCurrentData({
                                ...currentData,
                                action: e.target.value.id,
                              });

                              targets[active].action = e.target.value.id;
                              targets[active].action_name = e.target.value.name;
                            }}
                            value={currentData.action}
                            style={{ width: "100%", marginLeft: 13 }}
                          >
                            {callActions.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-4">
                          <AppButton
                            btnIcon="skip_next"
                            text="Next Call"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => saveCall()}
                          />
                        </div>
                        <div className="col-lg-4">
                          <AppButton
                            btnIcon="skip_next"
                            text="Convert to Response"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => convertResponse()}
                          />
                        </div>
                        <div className="col-lg-4">
                          <AppButton
                            btnIcon="save"
                            text="save as new activity"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() =>
                              handleSaveAsNewActivity(targets[active])
                            }
                          />
                        </div>

                        <div className="form-group col-lg-4">
                          <AppButton
                            btnIcon="arrow_back"
                            text="Go Back"
                            style={{
                              background: "blue",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => goBack()}
                          />
                        </div>

                        <div className="form-group col-lg-4">
                          <AppButton
                            btnIcon="skip"
                            text="view history"
                            style={{
                              background: "blue",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => handleView()}
                          />
                        </div>

                        <div className="form-group col-lg-4">
                          <AppButton
                            btnIcon="phone_disabled_icon"
                            text="Unsubscribe"
                            style={{
                              background: "red",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => handleUnsub()}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <p>{script}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <CircularProgress />
            <p>Loading</p>
          </div>
        )}
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel(active, data)} color="primary">
            Close
          </Button>
        </DialogActions>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={completeAlert}
          autoHideDuration={6000}
          onClose={hideCompleteAlert}
          message="All Calls Completed!"
          style={{
            background: "green",
            color: "white",
          }}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={hideCompleteAlert}
            >
              <DoneIcon style={{ color: "#4CA64C" }} fontSize="small" />
            </IconButton>
          }
        />
      </Dialog>
    </div>
  );
}
