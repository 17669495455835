import React, { useEffect, useState } from "react";
import PageHeader from "../../common/PageHeader";
import { Checkbox, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
//import { saveScripts } from "../../api/scripts";
import ApiLoader from "../../common/ApiLoader";

import {
  AppHeaderComponent,
  AppButtonComponent,
  ActivityApi,
} from "@unity/components";

export default function CallScriptCreate(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });
  let history = useHistory();
  const onSubmit = async () => {
    setLoading({
      status: !loading.status,
      data: "Saving your record, Please Wait....",
    });
    await ActivityApi.saveCallScript(data);
    setLoading({ status: true, data: "Save Success!, Please Wait...." });
    setTimeout(() => {
      history.push("/call-scripts/index");
    }, 2000);
  };

  return (
    <div>
      <AppHeaderComponent
        name={"call-scripts"}
        theme={props.context.theme}
        context={props.context}
        subpage={"create"}
        saveBtn={onSubmit}
      />
      <ApiLoader status={loading.status} data={loading.data} />

      <div style={{ paddingTop: 130 }}>
        <TextField
          label="Script Name"
          name="name"
          value={data.name}
          onChange={(e) =>
            setData({ ...data, [e.target.name]: e.target.value })
          }
          fullWidth
        />
        <TextField
          label="Script"
          name="script"
          value={data.script}
          onChange={(e) =>
            setData({ ...data, [e.target.name]: e.target.value })
          }
          fullWidth
          multiline
        />
      </div>
    </div>
  );
}
