import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ActivityApi, LoadingComponent, AppHeaderComponent } from "@unity/components";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function EmailTagIndex(props) {
    const [data, setData] = useState(false);

    const handleDelete = async (tag_id) => {
        const res = await ActivityApi.deleteEmailTag(tag_id);
        const tagRes = await ActivityApi.getEmailTags();

        setData(tagRes.data);
    };

    const getData = async () => {
        const res = await ActivityApi.getEmailTags();
        setData( res.data );
    }

    useEffect(() => {
        getData();
    }, []);

    if (data) {
        return (
            <>
                <AppHeaderComponent
                    name="activities"
                    subPage="index"
                    context={props.context}
                    theme={props.context.theme}
                />
                <div style={{ paddingTop: 120 }}>
                    <div className="col-lg-6">
                        {data.length === 0 && (
                            <h4>No Tags Found</h4>
                        )}
                        {data.map((value, key) => {
                            return (
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        id={key}
                                    >
                                        {value.name}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{
                                            display: "float"
                                        }}
                                        >
                                            <p>{value.description}</p>
                                            <div>
                                                <Button 
                                                    color="secondary"
                                                    varient="contained"
                                                    onClick={(e) => {
                                                        handleDelete(value.id)
                                                    }}
                                                >
                                                    Delete Tag
                                                </Button>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>
                    <div className="col-lg-2">
                        <div>
                        <Link
                            to={"/activities/emailTag/create"}
                        >
                            <Button
                                color="primary"
                                varient="contained"
                            >
                                Create
                            </Button>
                        </Link>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <LoadingComponent />
    }
}