import React from "react";

export default function TemplateKey() {
  return (
    <ul className="template-key">
      <li>
        First Name: <b>$first_name</b>
      </li>
      <li>
        Last Name: <b>$last_name</b>
      </li>
      <li>
        Email Address: <b>$email</b>
      </li>
    </ul>
  );
}
