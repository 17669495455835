import { InputLabel, Select, TextField, MenuItem } from "@material-ui/core";
import React from "react";
import FileBase64 from "react-file-base64";
import { AppButtonComponent } from "@unity/components";

export default function OnBoardingForm(props) {
  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>{props.title}</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>
                Activity Category
              </InputLabel>
              <Select
                fullWidth
                name="activity_category_id"
                value={props.data.activity.activity_category_id}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.categories &&
                  props.categories.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>
                Time Spent on Activity ( Hours )
              </InputLabel>
              <TextField
                name="minutes_spent"
                type="number"
                value={props.data.minutes_spent}
                onChange={(e) =>
                  props.handleRootChange(e.target.name, e.target.value)
                }
                fullWidth
              />
            </div>
            <div className="form-group col-lg-4">
              <InputLabel style={{ marginBottom: 3, fontSize: "13px" }}>
                Date Created
              </InputLabel>
              <TextField
                name="date_created"
                type="date"
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                value={props.data.date_created}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                name="name"
                label={`${props.title} Activity Name`}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                value={props.data.activity.name}
                fullWidth
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                name="description"
                multiline
                rows={4}
                label={`${props.title} Description`}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                value={props.data.activity.description}
                fullWidth
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <InputLabel>Upload Documents</InputLabel>
              <FileBase64
                multiple={false}
                onDone={(e) => props.handleUpload(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12" style={{ margin: 0 }}>
              <h3>Who Worked On This ?</h3>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: 20,
              flexWrap: "wrap",
              flexDirection: "row",
              flexGrow: "inherit",
            }}
          >
            {props.data.activity.users &&
              props.data.activity.users.map((item, key) => {
                return (
                  <div style={{ flex: 4, maxWidth: 350 }}>
                    <div
                      style={{
                        display: "flex",
                        width: 350,
                        marginTop: 20,
                        marginBottom: 20,
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <>
                        <div style={{ flex: 2, paddingRight: 10 }}>
                          <Select
                            fullWidth
                            value={item}
                            onChange={(e) =>
                              props.handleSelectUser(e.target.value, key)
                            }
                          >
                            {props.personnel.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                        <div style={{ flex: 1 }}>
                          <AppButtonComponent
                            onClick={() => props.handleDeleteUser(key)}
                            text="delete"
                            btnIcon="delete"
                            style={{
                              marginTop: 5,
                              marginRight: 15,
                              background: "red",
                              color: "white",
                            }}
                          />
                        </div>
                      </>
                    </div>
                  </div>
                );
              })}
          </div>
          <AppButtonComponent
            onClick={() => props.handleAddRecord()}
            text="add user"
            btnIcon="add"
            style={{ color: "white", background: "green" }}
          />
        </div>
      </div>
    </>
  );
}
