import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import {
  ClientsApi,
  SuppliersApi,
  AppHeaderComponent,
  AppButtonComponent,
} from "@unity/components";
import FilterTable from "../../common/FilterTable";

export default function CustomerIndex({ route, context }) {
  const [data, setData] = useState(false);

  const routePath = route.location.pathname.replace("/index", "");

  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].logo_path ? (
            <div
              style={{
                backgroundImage: "url(" + data[dataIndex].logo_path + ")",
                backgroundPosition: "center, center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          ) : (
            <div
              style={{
                backgroundImage:
                  "url(" +
                  "https://unity.world/wp-content/uploads/brizy/9/assets/images/iW=167&iH=44&oX=1&oY=0&cW=164&cH=44/Unity-Group.png" +
                  ")",
                backgroundPosition: "center, center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                height: 50,
                width: 50,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "#d2d2d2",
                borderRadius: "100%",
                display: "inline-block",
              }}
            />
          );
        },
      },
    },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "mobile", label: "Mobile" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <>
              <Link
                to={{
                  pathname: `${routePath}/${data[dataIndex].id}/index`,
                }}
              >
                <AppButtonComponent
                  btnIcon="visibility"
                  text="view"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
            </>
          );
        },
      },
    },
  ];

  const methods = {
    clients: ClientsApi.getClients,
    targets: ClientsApi.getClients,
    leads: ClientsApi.getClients,
    suppliers: SuppliersApi.getSuppliers,
  };

  const getData = async () => {
    const call = methods[route.match.params.customer_type];
    const res = await call(route.match.params.customer_type);
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  var select;

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
  };

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="activities"
          subpage="communication"
        />
        <div style={{ paddingTop: 140 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
