import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "../services/AuthContext";
import ActivitiesView from "../pages/communication/view";
import ActivitiesCreate from "../pages/communication/create";
import ActivitiesEdit from "../pages/communication/edit";
import ActivitiesIndex from "../pages/communication/activityIndex";
import CustomerIndexer from "../pages/common/customerIndex";
import TypeIndex from "../pages/communication/typeIndex";
import CustomerIndex from "../pages/common";
export default function CommunicationActivityRoutes(props) {
  const context = props.context;

  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route
                      path="/activities/communication/index"
                      exact
                      component={(route) => (
                        <CustomerIndexer
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_type/index"
                      exact
                      component={(route) => (
                        <CustomerIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_type/:customer_id/index"
                      exact
                      component={(route) => (
                        <TypeIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_type/:customer_id/:activity_type/index"
                      exact
                      component={(route) => (
                        <ActivitiesIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_type/:customer_id/responses/:activ_id/:type/index"
                      exact
                      component={(route) => (
                        <ActivitiesIndex
                          route={route}
                          history={history}
                          context={context}
                          response={true}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_type/:customer_id/:activity_type/view/:activity_id"
                      exact
                      component={(route) => (
                        <ActivitiesView
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_type/:customer_id/:activity_type/edit/:activity_id"
                      exact
                      component={(route) => (
                        <ActivitiesEdit
                          route={route}
                          context={context}
                          history={history}
                        />
                      )}
                    />

                    <Route
                      path="/activities/communication/:customer_id/create"
                      exact
                      component={(route) => (
                        <ActivitiesCreate
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />
                    <Route
                      path="/activities/communication/create"
                      exact
                      component={(route) => (
                        <ActivitiesCreate
                          createCustomerId={true}
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
