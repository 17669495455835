//This wrapper provides additional functionality to each activity form type

import { Checkbox, FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EventScheduler from "./EventScheduler";
import { Animated } from "react-animated-css";
import { CalendarApi } from "@unity/components";
import Loading from "../common/Loading";
import XSLoader from "../common/XSLoader";

export default function GlobalActivitySettingWrapper({ children, personnel, type, eventData, setEventData, event_id }) {

    const [settings, setSettings] = useState({
        calendar: event_id ? event_id : false
    });

    const handleChange = (name, val) => {
        setSettings({ ...settings, [name]: val })
    }

    const handleDataChange = (name, value) => {
        const newData = { ...eventData };
        newData[name] = value;
        setEventData(newData);
    }

    const getData = async () => {
        if (event_id) {
            const res = await CalendarApi.showEvent(event_id);
            var start = res.data.StartTime.replace(" ", "T");
            var finish = res.data.EndTime.replace(" ", "T");
            res.data.StartTime = start;
            res.data.EndTime = finish;
            setEventData(res.data);
        }
    }

    useEffect(() => {
        getData()
    }, [])


    return (
        <>
            <div className="block">
                <div className="container-fluid">
                    <div className="form-row">
                        <div className="col-lg-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="calendar"
                                        color="primary"
                                        checked={settings.calendar}
                                        onChange={(e) => handleChange(e.target.name, e.target.checked)}
                                    />
                                }
                                label="Calendar Event"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {settings.calendar &&
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                    {event_id ?
                        eventData ?
                            <EventScheduler personnel={personnel} type={type} data={eventData} handleChange={handleDataChange} />
                            :
                            <XSLoader />
                        :
                        <EventScheduler personnel={personnel} type={type} data={eventData} handleChange={handleDataChange} />
                    }
                </Animated>
            }
            {children}
        </>
    )
}