import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Checkbox, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Notification({
  open,
  description,
  title,
  accept,
  cancel,
  type,
  design,
  setDesign,
}) {
  const handleFormChange = (e) => {
    const data = { ...design };
    data[e.target.name] = e.target.value;
    setDesign(data);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={cancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p>{description}</p>
            {type === "template" && (
              <>
                <div style={{ marginBottom: 10 }}>
                  <TextField
                    name="template_name"
                    label="Template Name"
                    fullWidth
                    value={design.template_name}
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
                <div>
                  <TextField
                    name="template_description"
                    label="Template Description"
                    fullWidth
                    value={design.template_description}
                    onChange={(e) => handleFormChange(e)}
                  />
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => cancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => accept()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
