import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  PersonnelApi,
  ActivityApi,
  CalendarApi,
} from "@unity/components";
import NegotiationActivityForm from "../../modules/activities/forms/negotiate/NegotiationActivityForm";
import GlobalActivitySettingWrapper from "../GlobalActivitySettingWrapper";
import ApiLoader from "../../common/ApiLoader";
import { useHistory } from "react-router-dom";
import Loading from "../../common/Loading";

export default function NegotiateActivityEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [loading, setLoading] = useState({ status: false, data: false });
  const [eventData, setEventData] = useState(false);
  let history = useHistory();

  const handleSave = async () => {
    var event = data.event_id;
    if (data.event_id) {
      await CalendarApi.updateEvent(data.event_id, eventData);
    } else if (!data.event_id && eventData) {
      event = await CalendarApi.storeEvent(eventData);
    }
    data["event_id"] = event; //data.Id;
    setLoading({ status: true, data: "Saving Record, Please Wait..." });
    const x = await ActivityApi.updateActivity("negotiate", data, data.id);

    setLoading({ status: true, data: "Save Success, Please Wait" });
    setTimeout(() => {
      history.goBack();
    }, 3000);
  };

  const getData = async () => {
    const res = await ActivityApi.getActivitySingle(
      route.match.params.activity_id
    );
    setData(res.data);
  };

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const handleChange = (name, value) => {
    const newData = { ...data };
    newData["activity"][name] = value;
    setData(newData);
  };

  useEffect(() => {
    getData();
    getPersonnelData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="negotiation"
          subpage2="edit"
        />
        <ApiLoader status={loading.status} data={loading.data} />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <GlobalActivitySettingWrapper
            data={data}
            personnel={personnel}
            eventData={eventData}
            setEventData={setEventData}
          >
            <NegotiationActivityForm
              data={data}
              handleChange={handleChange}
              type="create"
            />
          </GlobalActivitySettingWrapper>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
