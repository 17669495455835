import React, { useEffect, useState } from "react";
import AppButton from "../../common/AppButton";
import Loading from "../../common/Loading";
import { Link, useHistory } from "react-router-dom";
import FilterTable from "../../common/FilterTable";

import {
  AppHeaderComponent,
  AppButtonComponent,
  DynamicListApi,
} from "@unity/components";

export default function ListCreatorIndex({ context, route, history }) {
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await DynamicListApi.getDynamicListIndex();

    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { name: "name", label: "List Name" },
    { name: "created_by_name", label: "Made By" },
    {
      name: "associate_category",
      label: "Associated Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              {data[dataIndex].associate_category == 4
                ? "Campaign"
                : data[dataIndex].associate_category}
            </>
          );
        },
      },
    },
    {
      name: "associate_id",
      label: "Associated Record",
      options: {
        filterList:
          typeof route.match.params.id != "undefined"
            ? [parseInt(route.match.params.id)]
            : [],
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              {/* <Link
                to={{
                  //Views the data inputed into the form with the correct ID
                  //Pathname
                  //@ forms is the name after the - in micro
                  //@ view is the file name in the folder
                  pathname: `/list-creator/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="View"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link> */}

              <Link
                to={{
                  pathname: `/list-creator/edit/${data[dataIndex].id}`,
                  state: { duplicate: false },
                }}
              >
                <AppButton
                  text="edit"
                  btnIcon="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </Link>
              <Link
                to={{
                  pathname: `/list-creator/duplicate/${data[dataIndex].id}`,
                  state: { duplicate: true },
                }}
              >
                <AppButton
                  text="duplicate"
                  btnIcon="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </Link>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: "none",
  };

  return (
    <>
      <AppHeaderComponent
        context={context}
        addNew={() => (
          <Link to={"/list-creator/create"}>
            <AppButtonComponent
              text="Add New"
              btnIcon="add"
              stylename="primary"
              style={{
                background: context.theme.top_menu.button_background,
                color: context.theme.top_menu.button_font,
              }}
            />
          </Link>
        )}
        theme={context.theme}
        name="list-creator"
      />

      <div style={{ paddingTop: 150 }}>
        <FilterTable columns={columns} data={data} options={options} />
      </div>
    </>
  );
}
