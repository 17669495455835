import React, { useState, useEffect } from "react";
import { ActivityApi, ApiLoaderComponent, AppHeaderComponent } from "@unity/components";
import TextField from "@material-ui/core/TextField";
import { Button, InputLabel } from "@material-ui/core";


export default function EmailTagCreate(props) {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState({ "status": false, "data": "" });

    const handleChange = (name, value) => {
        const newData = { ...data };
        newData[name] = value
        setData(newData);
    }

    const handleSave = async () => {
        setLoading({ "status": true, "data": "Saving Tag, Please Wait...."});
        const res = await ActivityApi.saveEmailTag(data);
        console.log(res);

        if( res.data )
        {
            setLoading({ "status": true, "data": "Saved Successfully" });
        }
        else
        {
            setLoading({ "status": true, "data": "An Error Has Occured, Please Try Again Later" });
        }

        setTimeout( () => {
            const newData = { "name": "", "description": "" };
            setData(newData);
            setLoading({"status": false, "data": ""});
        }, 4000)
    }

    return (
        <>
            <AppHeaderComponent
                name="activities"
                subPage="index"
                context={props.context}
                theme={props.context.theme}
            />
            <ApiLoaderComponent 
                status={loading.status} 
                data={loading.data}
            />
        
            <div style={{paddingTop: 120}}>
                <div className="form-row">
                    <div className="form-group col-lg-4">
                        <InputLabel>Tag Name:</InputLabel>
                        <TextField
                            name="name"
                            type="text"
                            value={data.name}
                            onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                            }}
                            fullWidth
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-lg-4">
                        <InputLabel>Tag Description:</InputLabel>
                        <TextField
                            name="description"
                            type="text"
                            value={data.description}
                            onChange={(e) => {
                                handleChange(e.target.name, e.target.value)
                            }}
                            fullWidth
                            multiline
                        />
                    </div>
                </div>
                <div className="form-row">
                    <Button
                        color="primary"
                        variant="contained" 
                        onClick={() => {
                            handleSave();
                        }}

                    >
                        Save
                    </Button>
                </div>
            </div> 
        </>
    )
}