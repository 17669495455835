import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import { AppHeaderComponent, AppButtonComponent, ActivityApi } from "@unity/components";
import FilterTable from "../../common/FilterTable";
import categoriesToNames from "./categoriesToNames";

export default function SalesActivityIndex({ route, context }) {
  const [data, setData] = useState(false);

  const cols = {
    "on-boarding": [
      { name: "tag", label: "Activity Tag" },
      { name: "category_id", label: "Activity Category" },
      { name: "time_spent", label: "Time Spent" },
      { name: "created_by", label: "Created By" },
      { name: "date_created", label: "Date Created" },
    ],

    "after-sales": [
      { name: "tag", label: "Activity Tag" },
      { name: "category_id", label: "Activity Category" },
      { name: "time_spent", label: "Time Spent" },
      { name: "created_by", label: "Created By" },
      { name: "date_created", label: "Date Created" },
    ],
  };

  const columns = [
    ...cols[route.match.params.activity_type],
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <>
              <Link
                to={{
                  pathname: `/activities/sales/${route.match.params.customer_type}/${route.match.params.customer_id}/${route.match.params.activity_type}/view/${data[dataIndex].id}`,
                }}
              >
                <AppButtonComponent
                  btnIcon="visibility"
                  text="view"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginRight: 15,
                  }}
                />
              </Link>

              <Link
                to={{
                  pathname: `/activities/sales/${route.match.params.customer_type}/${route.match.params.customer_id}/${route.match.params.activity_type}/edit/${data[dataIndex].id}`,
                }}
              >
                <AppButtonComponent
                  btnIcon="edit"
                  text="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
            </>
          );
        },
      },
    },
  ];

  const types = {
    "on-boarding": 1,
    "after-sales": 2,
  }
  const getData = async () => {
    const res = await ActivityApi.activitiesIndex("sale", route.match.params.customer_id, types[route.match.params.activity_type])
    setData(res.data)
  };

  useEffect(() => {
    getData();
  }, []);

  var select;

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
  };

  if (data) {
    return (
      <>
        <AppHeaderComponent

          context={context}
          theme={context.theme}
          name="activities"
          subpage="sales"
          subpage2="index"
        />
        <div style={{ paddingTop: 160 }}>
          <FilterTable
            columns={columns}
            data={data}
            options={options}
            title={`${categoriesToNames[route.match.params.activity_type]
              } Activities For `}
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
