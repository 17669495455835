import { InputLabel, Select, TextField, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ActivityApi, DynamicListApi, ClientsApi, TargetsApi } from "@unity/components";
export default function BulkEmailForm(props) {
  const [templateIndex, setTemplateIndex] = useState([]);
  const [listIndex, setListIndex] = useState([]);
  const [tags, setTags] = useState([]);
  const [listCount, setListCount] = useState("All");

  const getData = async () => {
    const res = await ActivityApi.getTemplatesIndex();
    setTemplateIndex(res.data);

    const listRes = await DynamicListApi.getDynamicListIndex();
    setListIndex(listRes.data);

    const tagRes = await ActivityApi.getEmailTags();
    setTags(tagRes.data);
  };

  const getListCount = async (listId) => {
    const list = listIndex.find(list => list.id == listId);

    let count = 0;

    if( list.list_type == "targets" )
    {
      const res = await TargetsApi.filterSetCountTargets( { "criteria": list.criteria } );
      count = res.data;
    }
    else
    {
      const res = await ClientsApi.getFilterCount(list.list_type, { "criteria": list.criteria } );
      count = res.data;
    }

    setListCount(count);
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div>
        <div className="form-row">
          <div className="form-group col-lg-4">
            <InputLabel>Activity Name</InputLabel>
            <TextField
              name="name"
              onChange={(e) =>
                props.handleActivityChange(e.target.name, e.target.value)
              }
              value={props.data.activity.name}
              fullWidth
            />
          </div>
          <div className="form-group col-lg-4">
            <InputLabel>Target List</InputLabel>
            <Select
              fullWidth
              name="list_id"
              value={
                props.data.activity.list_id ? props.data.activity.list_id : ""
              }
              onChange={(e) => {
                  props.handleDynamicListChange(e.target.name, e.target.value);
                  getListCount(e.target.value);
                }
              }
            >
              {listIndex.map((e, key) => (
                <MenuItem key={key} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="form-group col-lg-4">
            <InputLabel>Email Subject</InputLabel>
            <TextField
              name="subject"
              fullWidth
              onChange={(e) =>
                props.handleActivityChange(e.target.name, e.target.value)
              }
              value={
                props.data.activity.subject ? props.data.activity.subject : ""
              }
            />
          </div>
          <div className="form-group col-lg-4">
            <InputLabel>Template</InputLabel>
            <Select
              fullWidth
              name="template_id"
              value={
                props.data.activity.template_id
                  ? props.data.activity.template_id
                  : ""
              }
              onChange={(e) =>
                props.handleActivityChange(e.target.name, e.target.value)
              }
            >
              {templateIndex.map((e, key) => (
                <MenuItem key={key} value={e.id}>
                  {e.template_name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="form-group col-lg-4">
            <InputLabel>Start Date</InputLabel>
            <TextField
              name="scheduled_start"
              fullWidth
              type="datetime-local"
              onChange={(e) =>
                props.handleActivityChange(e.target.name, e.target.value)
              }
              value={
                props.data.activity.scheduled_start
                  ? props.data.activity.scheduled_start
                  : ""
              }
            />
          </div>
          <div className="col-lg-4">
          <InputLabel>Email Tag</InputLabel>
          <Select
            name="tag"
            value={props.data.activity.tag ? props.data.activity.tag : "Generic"}
            onChange={(e) =>
              props.handleActivityChange( e.target.name, e.target.value )
            }
            fullWidth
          >
            {tags && 
              tags.map((tag, key) => {
                return (
                  <MenuItem key={key} value={tag.name}>
                    {tag.name}
                  </MenuItem>
                );
              })
            }
          </Select>
        </div>
        <div className="col-lg-4">
          <InputLabel>Selected List Count: <span style={{ color: listCount > 1000 || listCount == 0 || listCount == "All" ? "red": "grey" }}>{listCount}</span></InputLabel>
        </div>
        </div>
      </div>
    </>
  );
}
