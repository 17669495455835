import { InputLabel, TextField, Select, MenuItem } from "@material-ui/core";
import { ActivityApi, DynamicListApi } from "@unity/components";
import React, { useState, useEffect } from "react";

export default function BulkCallForm(props) {
  const [listIndexData, setListIndexData] = useState([]);
  const [scriptData, setScriptData] = useState([]);

  const getData = async () => {
    const res = await DynamicListApi.getDynamicListIndex();
    const res2 = await ActivityApi.getCallScripts();
    setListIndexData(res.data);
    setScriptData(res2.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="form-row">
        <div className="form-group col-lg-4">
          <InputLabel>Activity Name</InputLabel>
          <TextField
            name="name"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.name}
            fullWidth
          />
        </div>
        <div className="form-group col-lg-4">
          <InputLabel>Target List</InputLabel>
          <Select
            fullWidth
            name="list_id"
            value={
              props.data.activity.list_id ? props.data.activity.list_id : ""
            }
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
          >
            {listIndexData.map((e, key) => (
              <MenuItem key={key} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="form-group col-lg-4">
          <InputLabel>Phone Subject</InputLabel>
          <TextField
            name="subject"
            fullWidth
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={
              props.data.activity.subject ? props.data.activity.subject : ""
            }
          />
        </div>
        <div className="form-group col-lg-4">
          <InputLabel>Script</InputLabel>
          <Select
            fullWidth
            name="script_id"
            value={
              props.data.activity.script_id ? props.data.activity.script_id : ""
            }
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
          >
            {scriptData.map((e, key) => (
              <MenuItem key={key} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </>
  );
}
