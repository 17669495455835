import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../../common/FilterTable";
import PageHeader from "../../common/PageHeader";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import AppButton from "../../common/AppButton";
import AuthContext from "../../services/AuthContext";

import {
  AppHeaderComponent,
  AppButtonComponent,
  ActivityApi,
} from "@unity/components";
//import { getScripts } from "../../api/scripts";
export default function CallScriptsIndex({ context, route, history }) {
  const [data, setData] = useState(false);

  const columns = [
    { name: "id", label: "Reference" },
    { name: "name", label: "Script Name" },
    { name: "created_by_name", label: "Created By" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          //const edit = CanUpdate("scripts");

          return (
            <div>
              <Link
                to={{
                  pathname: `/call-scripts/view/${data[dataIndex].id}`,
                  state: { client: data[dataIndex].id },
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              <Link
                to={{
                  pathname: `/call-scripts/edit/${data[dataIndex].id}`,
                  state: { name: data[dataIndex].name },
                }}
              >
                <AppButton
                  text="edit"
                  btnIcon="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </Link>
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {});
    },
  };

  const getData = async () => {
    const res = await ActivityApi.getCallScripts();
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <div>
        <AppHeaderComponent
          context={context}
          addNew={() => (
            <Link to={"/call-scripts/create"}>
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
          theme={context.theme}
          name="call-scripts"
        />
        <div style={{ paddingTop: 120 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <AppHeaderComponent
          context={context}
          addNew={() => (
            <Link to={"/call-scripts/create"}>
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
          theme={context.theme}
          name="call-scripts"
        />
        <Loading message={"Loading Data..."} />;
      </>
    );
  }
}
