import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import AppButton from "../../../../common/AppButton";
import {
  ActivityApi,
  ClientsApi,
  DynamicListApi,
  TargetsApi,
} from "@unity/components";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Hello there!
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListCreatorPopUp(props) {
  const [popUpData, setPopUpData] = useState({});
  const [autoFillData, setAutoFillData] = useState(false);
  const [listOption, setListOption] = useState(false);
  const [endPoint, setEndPoint] = useState("");
  const [selected, setSelected] = useState({});

  const handleChange = (name, value) => {
    setPopUpData({ ...popUpData, [name]: value });

    const find = listOptions.find((obj) => obj.name === value);

    setEndPoint(find);

    getData(props.endPointList, find.fieldName);
  };

  const handleAutoChange = (val) => {
    //setPopUpData({ ...popUpData });

    setPopUpData({ ...popUpData, ["value"]: val[endPoint.name] });
  };

  const delay = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  const handleCriteriaChange = async (val) => {
    //1 second delay
    delay(1000);

    getData(props.endPointList, endPoint.fieldName, val);
  };

  //End points for critera search, If you need more just follow the same format and should work right away
  const listOptions = [
    { id: 1, name: "town", fieldName: "fuzzyTownSearch" },
    { id: 2, name: "postcode", fieldName: "fuzzyPostcodesSearch" },
    { id: 3, name: "industry", fieldName: "fuzzyIndustrySearch" },
    { id: 4, name: "created_by", fieldName: "fuzzyCreatedBySearch" },
    { id: 5, name: "tag", fieldName: "fuzzyTagSearch" },
    {
      id: 6,
      name: "no_of_employees",
      fieldName: "fuzzyNoOfEmployeesSearch",
    },
    { id: 7, name: "region", fieldName: "fuzzyRegionSearch" },
    { id: 8, name: "sic_code", fieldName: "fuzzySicSearch" },
  ];

  const getData = async (endPointList, name, search) => {
    if (props.endPointList === "clients") {
      const res = await ClientsApi.getCriteriaFuzzySearch(
        endPointList,
        name,
        search
      );

      setAutoFillData(res.data);
    } else if (props.endPointList === "targets") {
      const res = await TargetsApi.getCriteriaFuzzySearch(
        endPointList,
        name,
        search
      );
      setAutoFillData(res.data);
    }
  };

  const addPopUpData = (popUpData) => {
    props.handlePopupData(popUpData);
    //setAutoFillData(false);
  };

  return (
    <div style={{ width: "800px!important" }}>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleCancel()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ maxWidth: "800px!important", minWidth: "500px!important" }}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-slide-title">
          List Creator Added
        </DialogTitle>
        <DialogContent>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <Select
                    name="name"
                    label=""
                    value={popUpData.name ? popUpData.name : ""}
                    onChange={(e) =>
                      handleChange(e.target.name, e.target.value)
                    }
                    fullWidth
                  >
                    {listOptions.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="row">
                  <Autocomplete
                    disabled={!autoFillData}
                    options={autoFillData}
                    getOptionLabel={(options) =>
                      "" ||
                      options.town ||
                      options.created_by ||
                      options.industry ||
                      options.postcode ||
                      options.tag ||
                      options.no_of_employees ||
                      options.region ||
                      options.sic_code
                    }
                    fullWidth
                    onChange={(e, val) => handleAutoChange(val)}
                    onInputChange={(e, val) => handleCriteriaChange(val)}
                    renderInput={(params) => <TextField {...params} label="" />}
                    clearOnBlur={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => addPopUpData(popUpData)}>Add</Button>
          <Button onClick={() => props.handleDelete(popUpData)}>Remove</Button>
          <Button onClick={() => props.handleCancel()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
