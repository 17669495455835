import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import AppButton from "../../../common/AppButton";
import {
  ActivityApi,
  ClientsApi,
  DynamicListApi,
  CampaignApi,
  TargetsApi,
} from "@unity/components";

import CallBoxClients from "./CallBoxClients";
import CallBoxTargets from "./CallBoxTargets";

// Hello there!
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CallBox({
  open,
  description,
  title,
  accept,
  handleCancel,
  type,
  data,
  campaign_id,
}) {
  const [stage, setStage] = useState(1);
  //1 start //2 start call // 3 call on hold // 4 ended
  const [targets, setTargets] = useState(false);
  const [script, setScript] = useState("");
  const [number, setNumber] = useState();
  const [active, setActive] = useState(0); //the current active key
  const [resultTypes, setResultTypes] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [callActions, setCallActions] = useState([]);
  const [completeAlert, setCompleteAlert] = useState(false);
  const [callBoxData, setCallBoxData] = useState([]);
  const [dynamicList, setDynamicList] = useState(false);

  const [selectedClient, setSelectedClient] = useState({});

  const [listType, setListType] = useState("");

  const handleSelectedClient = (client) => {
    setSelectedClient(client);

    setNumber(client.phone);

    if (!client.phone_pref) {
      alert("Contact Does Not Wish To Be Called");
    }
  };

  const showCompleteAlert = () => {
    setCompleteAlert(true);
  };

  const hideCompleteAlert = () => {
    setCompleteAlert(false);

    handleCancel(active, data);
  };

  const convertResponse = async () => {
    const obj = {
      campaign_id: data.association,
      client_id: targets[active].customer_id,
      subject: "Call List Response",
      body: currentData.notes,
      type: "Phone",
    };

    const res = CampaignApi.saveResponse(obj);

    const savedData = {
      model: "Contact-Service/Customer",
      id: targets[active].customer_id,
      action: "Converted To a Response",
      description: `Notes: ${currentData.notes}, Action: ${currentData.action}`,
    };
    const res2 = await ActivityApi.saveLog(savedData);

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
    nextCall();
  };

  const handleSaveAsNewActivity = async (target) => {
    alert("Saving as new Activity");
    if (listType === "clients") {
      const obj = {
        activity: {
          contact_phone: target.active_site_contacts[0]
            ? target.active_site_contacts[0].phone
            : "",
          name: target.active_site_contacts[0].name + "" + "Call Back",
          notes: script + "" + currentData.notes,
        },
        association: data.association,
        association_category: data.association_category,
        customer_id: data.customer_id,
        deadline_date: data.deadline_date,
        responsible_id: data.created_by_id,
        type: 1,
        tag: "Campaign",
      };
      const x = await ActivityApi.saveActivity("phone", obj);
    } else if (listType === "targets") {
      const obj = {
        activity: {
          contact_phone: target.contact_phone ? target.contact_phone : "",
          name: target.company_name + "" + "Call Back",
          notes: script + "" + currentData.notes,
        },
        association: data.association,
        association_category: data.association_category,
        customer_id: data.customer_id,
        deadline_date: data.deadline_date,
        responsible_id: data.created_by_id,
        type: 1,
        tag: "Campaign",
      };
      const x = await ActivityApi.saveActivity("phone", obj);
    }

    nextCall();
  };

  const nextCall = () => {
    if (active + 1 <= targets.length - 1) {
      setActive(active + 1);
      setNumber(false);
      setStage(1);
      setCurrentData({});
      setSelectedClient({});
    }

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
  };

  const goBack = () => {
    if (active - 1 < 0) {
      return;
    } else {
      setActive(active - 1);
      setNumber(false);
      setStage(1);
      setCurrentData({});
      currentData.notes = "";
      setSelectedClient({});
    }
  };

  const saveCall = async () => {
    const savedData = {
      model: "Contact-Service/Customer",
      id: targets[active].customer_id,
      action: "Customer Was Called",
      description: `Notes: ${currentData.notes}, Action: ${currentData.action}`,
    };
    const res = await ActivityApi.saveLog(savedData);

    if (active + 1 <= targets.length - 1) {
      setActive(active + 1);
      setNumber(false);
      setStage(1);
      setCurrentData({});
      setSelectedClient({});
      var notesField = document.getElementById("notesField");
      notesField.value = "";
      return;
    }

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
  };

  const getData = async () => {
    console.log(data);
    if (data.activity.script_id === null) setScript("No Script Selected");
    else {
      const res = await ActivityApi.getCallScriptSingle(
        data.activity.script_id
      );
      setScript(res.data.script);
    }
    if (!data.activity.index) {
      setActive(0);
    } else {
      setActive(data.activity.index);
    }

    //Grab the critera for the list
    const listCriRes = await DynamicListApi.getDynamicListSingle(
      data.activity.list_id
    );

    setListType(listCriRes.data.list_type);

    listCriRes.data.criteria["has_phone"] = true;

    //Send that up to the filter to get the clients back
    if (listCriRes.data.list_type === "clients") {
      const filteredListRes = await ClientsApi.filterSetPaginate(
        listCriRes.data.list_type,
        listCriRes.data.criteria
      );
      //set the targets as clients.
      setTargets(filteredListRes.data.data);
    } else if (listCriRes.data.list_type === "targets") {
      // const newObj = testEmptyArray(listCriRes.data.criteria);

      //const filterListRes = await TargetsApi.filterSetPaginateTargets(newObj);

      const tempObj = {
        criteria: listCriRes.data.criteria,
        paginate_search: "",
      };
      const filterListRes = await TargetsApi.filterSetPaginateTargets(tempObj);

      setTargets(filterListRes.data.data);
    }
  };

  //NEED TO CHANGE THIS WHEN I GET THE CHANCE
  const testEmptyArray = (arr) => {
    let newObj = {};

    //Very hacky way to check if the array is empty and if it is set value to NULL since targets isnt accepting empty arrys for null...

    //TOWN
    arr.town != null && arr.town.length <= 0
      ? (newObj["town"] = null)
      : (newObj["town"] = arr.town);

    //POSTCODE
    arr.postcode != null && arr.postcode.length <= 0
      ? (newObj["postcode"] = null)
      : (newObj["postcode"] = arr.postcode);

    //CREATED BY
    arr.created_by != null && arr.created_by.length <= 0
      ? (newObj["created_by"] = null)
      : (newObj["created_by"] = arr.created_by);

    //INDUSTRY
    arr.industry != null && arr.industry.length <= 0
      ? (newObj["industry"] = null)
      : (newObj["industry"] = arr.industry);

    //NO OF EMPLOYESS START
    arr.no_of_employees_start != null && arr.no_of_employees_start.length <= 0
      ? (newObj["no_of_employees_start"] = null)
      : (newObj["no_of_employees_start"] = arr.no_of_employees_start);
    //NO OF EMPLOYESS END
    arr.no_of_employees_end != null && arr.no_of_employees_end.length <= 0
      ? (newObj["no_of_employees_end"] = null)
      : (newObj["no_of_employees_end"] = arr.no_of_employees_end);
    //CREATE AT START
    arr.created_at_start != null && arr.created_at_start.length <= 0
      ? (newObj["created_at_start"] = null)
      : (newObj["created_at_start"] = arr.created_at_start);
    //CREATE AT END
    arr.created_at_end != null && arr.created_at_end.length <= 0
      ? (newObj["created_at_end"] = null)
      : (newObj["created_at_end"] = arr.created_at_end);

    //UPDATED AT START
    arr.updated_at_start != null && arr.updated_at_start.length <= 0
      ? (newObj["updated_at_start"] = null)
      : (newObj["updated_at_start"] = arr.updated_at_start);
    //UPDATED AT END
    arr.updated_at_end != null && arr.updated_at_end.length <= 0
      ? (newObj["updated_at_end"] = null)
      : (newObj["updated_at_end"] = arr.updated_at_end);

    //ADD
    arr.add != null && arr.add.length <= 0
      ? (newObj["add"] = null)
      : (newObj["add"] = arr.add);

    //REMOVE
    arr.remove != null && arr.remove.length <= 0
      ? (newObj["remove"] = null)
      : (newObj["remove"] = arr.remove);

    //CREATED AT BY NAME
    arr.created_by_name != null && arr.created_by_name.length <= 0
      ? (newObj["created_by_name"] = null)
      : (newObj["created_by_name"] = arr.created_by_name);
    return { criteria: newObj, paginate_search: "" };
  };

  const getCallResultTypes = async () => {
    const res = await ActivityApi.getCallActions();
    setCallActions(res.data);
  };

  useEffect(() => {
    getData();
    getCallResultTypes();
  }, []);

  if (data && listType === "clients") {
    return (
      <CallBoxClients
        open={open}
        title={title}
        data={data}
        stage={stage}
        script={script}
        currentData={currentData}
        targets={targets}
        handleCancel={handleCancel}
        active={active}
        number={number}
        callActions={callActions}
        setStage={setStage}
        setCurrentData={setCurrentData}
        setActive={setActive}
        setNumber={setNumber}
        goBack={goBack}
        handleSelectedClient={handleSelectedClient}
        nextCall={nextCall}
        listType={listType}
        handleSaveAsNewActivity={handleSaveAsNewActivity}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
      />
    );
  } else if (data && listType === "targets") {
    return (
      <CallBoxTargets
        open={open}
        title={title}
        data={data}
        stage={stage}
        script={script}
        currentData={currentData}
        targets={targets}
        handleCancel={handleCancel}
        active={active}
        number={number}
        callActions={callActions}
        setStage={setStage}
        setCurrentData={setCurrentData}
        setActive={setActive}
        setNumber={setNumber}
        goBack={goBack}
        handleSelectedClient={handleSelectedClient}
        nextCall={nextCall}
        listType={listType}
        handleSaveAsNewActivity={handleSaveAsNewActivity}
      />
    );
  } else return <p>Loading</p>;
}
