import React, { useState, useEffect, useContext } from "react";

import {
  AppHeaderComponent,
  ActivityApi,
  AppButtonComponent,
  PersonnelApi,
} from "@unity/components";
import FilterTable from "../../common/FilterTable";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import AppButton from "../../common/AppButton";
export default function TemplateIndex({ context, route, history }) {
  const [data, setData] = useState(false);
  const [personnel, setPersonnel] = useState([{}]);

  /*   if (CanDelete(context)) {
    select = "multiple";
  } else {
    select = "none";
  } */

  const getAllTemplates = async () => {
    const res = await ActivityApi.getTemplatesIndex();
    setData(res.data);
  };
  const getPersonnel = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  useEffect(() => {
    getAllTemplates();
    getPersonnel();
  }, []);

  const columns = [
    { name: "id", label: "Reference" },
    {
      name: "created_by",
      label: "Created By",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const id = data[dataIndex].created_by;

          if (id) {
            const data = personnel.find((e) => e.uuid == id);

            if (data != undefined) {
              return data.name;
            }
          }
        },
      },
    },
    { name: "template_name", label: "Template Name" },
    { name: "template_description", label: "Template Description" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          // const edit = CanUpdate("email-templates");

          return (
            <div>
              <Link
                to={{
                  pathname: `/email-templates/view/${data[dataIndex].id}`,
                  state: { client: data[dataIndex].id },
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
              <Link
                to={{
                  pathname: `/email-templates/edit/${data[dataIndex].id}`,
                  state: { client: data[dataIndex].id },
                }}
              >
                <AppButton
                  text="edit"
                  btnIcon="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </Link>

              <Link
                to={{
                  pathname: `/email-templates/duplicate/${data[dataIndex].id}`,
                  state: { client: data[dataIndex].id, duplicate: true },
                }}
              >
                <AppButton
                  text="Duplicate"
                  btnIcon="content_copy"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </Link>
            </div>
          );
        },
        filter: false,
      },
    },
  ];
  /* 
  if (CanDelete("email-templates")) {
    var select = "multiple";
  } else {
    var select = "none";
  } */

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        const res = await deleteTemplate(data[item.dataIndex].id);
        console.log("res", res);
      });
    },
  };

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          addNew={() => (
            <Link to={"/email-templates/create"}>
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
          theme={context.theme}
          name="email-templates"
        />
        <div style={{ paddingTop: 130 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
