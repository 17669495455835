import React, { useEffect, useState } from "react";
import PageHeader from "../../common/PageHeader";
import { Checkbox, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
//import { saveScripts } from "../../api/scripts";
import ApiLoader from "../../common/ApiLoader";

import Loading from "../../common/Loading";

import {
  AppHeaderComponent,
  AppButtonComponent,
  ActivityApi,
} from "@unity/components";
export default function CallScriptView(props) {
  const [data, setData] = useState(false);

  const getData = async () => {
    const res = await ActivityApi.getCallScriptSingle(
      props.route.match.params.id
    );
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <div>
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          name="call-scripts"
        />

        <div style={{ paddingTop: 120 }}>
          <TextField
            label="Script Name"
            name="name"
            value={data.name}
            fullWidth
          />

          <div style={{ width: "100%" }}>
            <div
              style={{ width: "100%", display: "inline-block", paddingTop: 20 }}
              dangerouslySetInnerHTML={{ __html: data.script }}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}
