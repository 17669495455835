import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import CallScriptsIndex from "../pages/call-scripts";
import CallScriptCreate from "../pages/call-scripts/create";
import CallScriptEdit from "../pages/call-scripts/edit";
import CallScriptView from "../pages/call-scripts/view";
export default function CallScriptsRoutes({ context, route, history }) {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <section className="route-section">
                <Switch location={location}>
                  <Route
                    path="/call-scripts/index"
                    exact
                    component={(route) => (
                      <CallScriptsIndex
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/call-scripts/create"
                    exact
                    component={(route) => (
                      <CallScriptCreate
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/call-scripts/edit/:id"
                    exact
                    component={(route) => (
                      <CallScriptEdit
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/call-scripts/view/:id"
                    exact
                    component={(route) => (
                      <CallScriptView
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}
