import React from "react";
import TextField from "@material-ui/core/TextField";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { formTypes, types, typeTags } from "../../../../constants";

//form sets init state
//update creates array of updated values

export default function CommunicationActivityForm(props) {
  const Form = formTypes[props.data.type];

  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Association</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>Association Category</InputLabel>
              <Select
                name="association_category"
                fullWidth
                value={
                  props.data.association_category
                    ? props.data.association_category
                    : ""
                }
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.associations &&
                  props.associations.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Association</InputLabel>
              {props.data.association_category ? (
                <>
                  {!props.associationLoading ? (
                    <Select
                      name="association"
                      fullWidth
                      value={
                        props.data.association ? props.data.association : ""
                      }
                      onChange={(e) =>
                        props.handleChange(e.target.name, e.target.value)
                      }
                    >
                      {props.associationData &&
                        props.associationData.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item.id}>
                              {item.name && item.name}
                              {item.ticket_name && item.ticket_name}
                              {item.campaign_name && item.campaign_name}
                              {item.sales_opp_overview &&
                                item.sales_opp_overview}
                              {item.reference && item.reference}
                              {item.subject && item.subject}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  ) : (
                    <p>Loading Please Wait....</p>
                  )}
                </>
              ) : (
                <Select value={0} fullWidth>
                  <MenuItem value={0}>
                    Please select an association category
                  </MenuItem>
                </Select>
              )}
            </div>
            {props.createTypeTag && (
              <div className="form-group col-lg-4">
                <InputLabel>Type Tag</InputLabel>
                <Select
                  name="tag"
                  value={props.data.tag ? props.data.tag : ""}
                  onChange={(e) =>
                    props.handleChange(e.target.name, e.target.value)
                  }
                  fullWidth
                >
                  {typeTags.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Activity Information</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>Who is responsible?</InputLabel>
              <Select
                name="responsible_id"
                fullWidth
                value={
                  props.data.responsible_id ? props.data.responsible_id : ""
                }
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
              >
                {props.personnel &&
                  props.personnel.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name && item.name}
                        {item.ticket_name && item.ticket_name}
                        {item.campaign_name && item.campaign_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Deadline Date</InputLabel>

              <TextField
                name="deadline_date"
                type="date"
                value={props.data.deadline_date}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
            <div className="form-group col-lg-4">
              <InputLabel>Minutes Spent</InputLabel>
              <TextField
                name="minutes_spent"
                type="number"
                value={props.data.minutes_spent}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth={true}
              ></TextField>
            </div>
          </div>
        </div>
      </div>

      <div className="block" style={{ paddingBottom: 20 }}>
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Activity Type</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-4">
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={props.data.type ? props.data.type : ""}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                fullWidth
              >
                {types.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </div>

          {props.data.type && (
            <Form
              handleActivityChange={props.handleActivityChange}
              handleChange={props.handleChange}
              handleDynamicListChange={props.handleDynamicListChange}
              data={props.data}
              leads={props.leads}
            />
          )}
        </div>
      </div>
    </>
  );
}
