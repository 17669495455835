import React from "react";
import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";

export default function CustomForm(props) {
    return (

        <div>
            <div className="form-row">
                <div className="col-lg-4">
                    <InputLabel >Activity Name</InputLabel>
                    <TextField name="name" onChange={(e) => props.handleActivityChange(e.target.name, e.target.value)} value={props.data.activity.name} fullWidth />
                </div>
            </div>

            <div className="form-row">
                <div className="col-lg-12">
                    <InputLabel >Activity Description</InputLabel>
                    <TextField multiline rows={12} name="description" onChange={(e) => props.handleActivityChange(e.target.name, e.target.value)} value={props.data.activity.description} fullWidth />
                </div>
            </div>
        </div>

    )
}