import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "../services/AuthContext";
import CustomerIndexer from "../pages/common/customerIndex";
import CustomerIndex from "../pages/common";
import GoIntegratorCustomerLogs from "../pages/go-integrator/customerLogs";
export default function GoIntegratorActivityRoutes(props) {
  const context = props.context;

  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route
                      path="/activities/go-integrator/index"
                      exact
                      component={(route) => (
                        <CustomerIndexer
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/go-integrator/:customer_type/index"
                      exact
                      component={(route) => (
                        <CustomerIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/go-integrator/:customer_type/:customer_id/index"
                      exact
                      component={(route) => (
                        <GoIntegratorCustomerLogs
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />


                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
