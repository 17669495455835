import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import {
  AppHeaderComponent,
  AppButtonComponent,
  ActivityApi,
} from "@unity/components";
import FilterTable from "../../common/FilterTable";
import CallBox from "../../modules/activities/calls/CallBox";
import { associations, types } from "../../constants";
import { Tooltip } from "@material-ui/core";

export default function ActivitiesIndex({ route, context, response }) {
  const [data, setData] = useState([]);
  const [callBox, setCallBox] = useState(false);
  const [dataIndexs, setDataIndexs] = useState([]);

  const saveAction = async (id, sub) => {
    const savedData = {
      model: "Marketing-Service/Campaign",
      id: id,
      action: "Customer Was Emailed",
      description: `Emailed the customer, Subject: ${sub}`,
    };
    const res = await ActivityApi.saveLog(savedData);
  };

  const handleBulkEmailCancel = async (id) => {
    if (confirm("Cancel Bulk Email?")) {
      const res = await ActivityApi.deleteBulkEmail(id);
    }
  };

  const handleBulkEmailStatus = async (id) => {
    const res = await ActivityApi.getBatchStatus(id);

    alert(
      `In Queue: ${res.data.total_jobs}, Processed: ${
        res.data.completed_jobs
      }, Stopped: ${res.data.stopped ? "Yes" : "No"}`
    );
  };

  const sendEmails = async (id) => {
    const res = await ActivityApi.sendEmail(id);
    console.log(res);
    alert(res.data);
  };

  const columns = [
    {
      name: "tag",
      label: "Activity Tag",
      options: {
        filterList: route.location.state
          ? [route.location.state && route.location.state.filter]
          : false,
      },
    },
    {
      name: "name",
      label: "Activity Name",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <span>{data[dataIndex].activity.name}</span>;
        },
      },
    },
    {
      name: "type",
      label: "Activity Type",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const typeObject = types.find((e) => e.id == data[dataIndex].type);
          return typeObject.name;
        },
      },
    },
    {
      name: "association_category",
      label: "Assoc Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const category_id = data[dataIndex].association_category;
          if (category_id) {
            const data = associations.find((e) => e.id == category_id);
            return data.name;
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "association_link",
      label: "Assoc Link",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const category_id = data[dataIndex].association_category;
          if (category_id) {
            const el = associations.find((e) => e.id == category_id);
            return (
              <Link to={`${el.link}${data[dataIndex].association}`}>
                {data[dataIndex].association}
              </Link>
            );
          } else {
            return "";
          }
        },
      },
    },

    { name: "created_by", label: "Created By" },
    { name: "deadline_date", label: "Deadline Date" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = true;

          return (
            <>
              <Link
                to={{
                  pathname: `/activities/communication/${route.match.params.customer_type}/${route.match.params.customer_id}/${route.match.params.activity_type}/view/${data[dataIndex].id}`,
                }}
              >
                <AppButtonComponent
                  btnIcon="visibility"
                  text="view"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginRight: 15,
                  }}
                />
              </Link>


              {data[dataIndex].type != 6 ? (
                <Link
                  to={{
                    pathname: `/activities/communication/${route.match.params.customer_type}/${route.match.params.customer_id}/${route.match.params.activity_type}/edit/${data[dataIndex].id}`,
                  }}
                >
                <AppButtonComponent
                  btnIcon="edit"
                  text="edit"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginRight: 15,
                  }}
                />
                </Link>
              ) : (
                <Tooltip title="Cannot Edit This Activity Type">
                  <span>
                    <AppButtonComponent
                      btnIcon="edit"
                      text="edit"
                      style={{
                        background: "grey",
                        color: "white",
                        marginRight: 15,
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              {data[dataIndex].type == 2 && (
                <>
                  <a
                    href={encodeURI(
                      "mailto:" +
                        data[dataIndex].activity.to +
                        "?subject=" +
                        data[dataIndex].activity.subject +
                        "&body=" +
                        data[dataIndex].activity.body
                    )}
                  >
                    <AppButtonComponent
                      btnIcon="send"
                      text="email"
                      style={{
                        background: "green",
                        color: "white",
                      }}
                      onClick={() => {
                        saveAction(
                          data[dataIndex].association,
                          data[dataIndex].activity.subject
                        );

                        //sendEmails(data[dataIndex].id);
                      }}
                      /*                   onClick={
                      (() =>
                        saveAction(
                          data[dataIndex].association,
                          data[dataIndex].activity.subject
                        );
                      console.log(data[dataIndex].activity))
                          // ActivityApi.sendEmail(data[dataIndex].activity.id))
                    } */
                    />
                  </a>
                </>
              )}
              {data[dataIndex].type == 1 && (
                <>
                  <a href={"dial:" + data[dataIndex].activity.contact_phone}>
                    <AppButtonComponent
                      btnIcon="phone"
                      text="call"
                      style={{
                        background: "green",
                        color: "white",
                      }}
                    />
                  </a>
                </>
              )}
              {data[dataIndex].type == 5 && (
                <>
                  <AppButtonComponent
                    btnIcon="phone"
                    text="Bulk Call"
                    style={{
                      background: "green",
                      color: "white",
                    }}
                    onClick={() => {
                      setCallBox(true);
                      setDataIndexs(data[dataIndex]);
                    }}
                  />
                </>
              )}

              {data[dataIndex].type == 6 && (
                <>
                  <AppButtonComponent
                    btnIcon="visibility"
                    text="Status"
                    style={{
                      background: "green",
                      color: "white",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      handleBulkEmailStatus(data[dataIndex].id);
                    }}
                  />
                  <AppButtonComponent
                    btnIcon="cancel"
                    style={{
                      background: "red",
                      color: "white",
                    }}
                    onClick={() => {
                      handleBulkEmailCancel(data[dataIndex].id);
                    }}
                  />
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const handleCancel = async (activeID = 1, data) => {
    setCallBox(false);

    const newData = { ...data };
    newData.activity.index = activeID;

    const res = await ActivityApi.updateActivity("bulkPhone", data, newData.id);
  };

  const getData = async () => {
    if (response === true) {
      const res = await ActivityApi.getActivityByAssociation(
        route.match.params.activ_id,
        route.match.params.type
      );
      setData(res.data);
    } else {
      const res = await ActivityApi.activitiesIndex(
        "communication",
        route.match.params.customer_id,
        1
      );
      setData(res.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  var select;

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
  };

  console.log("Activitys Index", data);

  if (data) {
    return (
      <>
        {callBox && (
          <CallBox
            open={callBox}
            handleCancel={handleCancel}
            data={dataIndexs}
          />
        )}
        <AppHeaderComponent
          addNew={() => (
            <Link
              to={{
                pathname: "/activities/communication/create",
                state: {
                  customer_id: route.match.params.customer_id,
                  tag: "indexPage",
                },
              }}
            >
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
          context={context}
          theme={context.theme}
          name="activities"
          subpage="communication"
          subpage2="index"
        />
        <div style={{ paddingTop: 140 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
