import React, { useEffect, useState } from "react";
import { TextField, Select, MenuItem, InputLabel } from "@material-ui/core";
import { ClientsApi, PersonnelApi } from "@unity/components";

export default function LetterForm(props) {
  const [addresses, setAddresses] = useState(false);
  const [personnel, setPersonnel] = useState([]);

  const getAddresses = async () => {
    if (props.data.associated_lead) {
      const leadObj = props.leads.find(
        (e) => e.id === props.data.associated_lead
      );
      const res = await ClientsApi.getClientSingle(leadObj.client_id);
      setAddresses(res.data);
    }
  };

  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  const copyAddress = (address) => {
    props.handleActivityChange("address", address);
  };

  useEffect(() => {
    getAddresses();
    getPersonnelData();
  }, []);

  return (
    <>
      <div className="form-row">
        <div className="col-lg-4">
          <InputLabel>Activity Name</InputLabel>
          <TextField
            name="name"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.name}
            fullWidth
          />
        </div>

        <div className="col-lg-4">
          <InputLabel>Who is this from ?</InputLabel>
          <Select
            fullWidth
            name="sender_id"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.sender_id}
          >
            {personnel.map((item, key) => {
              return (
                <MenuItem key={key} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>

        <div className="col-lg-4">
          <InputLabel>Letter Address</InputLabel>
          {props.data.associated_lead ? (
            addresses && addresses.length > 0 ? (
              <Select
                fullWidth
                value={props.data.activity.address_id}
                name="address_id"
                onChange={(e) => {
                  props.handleActivityChange(e.target.name, e.target.value);
                  copyAddress(e.target.value);
                }}
                value={props.data.activity.address_id}
              ></Select>
            ) : (
              <Select fullWidth value={0}>
                <MenuItem value={0}>
                  This lead has no associated addresses
                </MenuItem>
              </Select>
            )
          ) : (
            <Select fullWidth value={0}>
              <MenuItem value={0}>Please choose an associated lead</MenuItem>
            </Select>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-6">
          <InputLabel style={{ paddingTop: 30 }}>Letter Address</InputLabel>
          <TextField
            name="address"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.address}
            fullWidth
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-lg-12">
          <InputLabel style={{ paddingTop: 30 }}>Letter Body</InputLabel>
          <TextField
            name="body"
            onChange={(e) =>
              props.handleActivityChange(e.target.name, e.target.value)
            }
            value={props.data.activity.body}
            rows={12}
            multiline
            fullWidth
          />
        </div>
      </div>
    </>
  );
}
