import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  PersonnelApi,
  ActivityApi,
  CalendarApi,
} from "@unity/components";
import NegotiationActivityForm from "../../modules/activities/forms/negotiate/NegotiationActivityForm";
import { useHistory } from "react-router";
import Loading from "../../common/Loading";
import GlobalActivitySettingWrapper from "../GlobalActivitySettingWrapper";

export default function NegotiateActivityView({ route, context }) {
  const [data, setData] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [loading, setLoading] = useState({ status: false, data: false });
  const [eventData, setEventData] = useState(false);
  let history = useHistory;

  const handleSave = async () => {};

  const handleChange = (name, value) => {};

  const getData = async () => {
    const res = await ActivityApi.getActivitySingle(
      route.match.params.activity_id
    );
    setData(res.data);
  };
  const getPersonnelData = async () => {
    const res = await PersonnelApi.getPersonnel();
    setPersonnel(res.data);
  };

  useEffect(() => {
    getData();
    getPersonnelData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="activities"
          subpage="negotiation"
          subpage2="view"
        />

        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <NegotiationActivityForm
            data={data}
            handleChange={handleChange}
            type="view"
          />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
