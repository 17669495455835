import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

//form sets init state
//update creates array of updated values

export default function NegotiationActivityForm(props) {
  return (
    <>
      <div className="block" style={{ paddingBottom: 20 }}>
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-12">
              <h3>Negotiation Form</h3>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                disabled={props.type === "view"}
                label="Which element of our solution does the customer want to negotiate ?"
                name="negotiate_element"
                fullWidth
                value={props.data.activity.negotiate_element}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                multiline
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-lg-12">
              <TextField
                disabled={props.type === "view"}
                label="Propsed negotiation solution ?"
                name="negotiate_solution"
                fullWidth
                value={props.data.activity.negotiate_solution}
                onChange={(e) =>
                  props.handleChange(e.target.name, e.target.value)
                }
                multiline
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
