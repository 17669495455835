import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import {
  AppHeaderComponent,
  ClientsApi,
  DynamicListApi,
  AppButtonComponent,
} from "@unity/components";
import ListCreatorForm from "../../modules/activities/forms/list-creator/listCreatorForm";
import { Link, useHistory } from "react-router-dom";

export default function ListCreatorView({ context, route }) {
  const [data, setData] = useState(false);

  const getListSingle = async () => {
    const res = await DynamicListApi.getDynamicListSingle(
      route.match.params.id
    );
    setData(res.data);
  };

  const handleUpload = (e) => {};

  const handleChange = (name, value) => {};

  useEffect(() => {
    getListSingle();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="list-creator"
        />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <ListCreatorForm data={data} type="view" />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
