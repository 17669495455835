import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "../services/AuthContext";
import TemplateIndex from "../pages/email-templates/index";
import TemplateCreate from "../pages/email-templates/create";
import TemplateView from "../pages/email-templates/view";
import TemplateEdit from "../pages/email-templates/edit";
export default function EmailTemplatesRoute({ context, route, history }) {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup className="transition-group">
            <CSSTransition key={location.key} timeout={300} classNames="fade">
              <section className="route-section">
                <Switch location={location}>
                  <Route
                    path="/email-templates/index"
                    exact
                    component={(route) => (
                      <TemplateIndex
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/email-templates/create"
                    exact
                    component={(route) => (
                      <TemplateCreate
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/email-templates/view/:id"
                    exact
                    component={(route) => (
                      <TemplateView
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/email-templates/edit/:id"
                    exact
                    component={(route) => (
                      <TemplateEdit
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />

                  <Route
                    path="/email-templates/duplicate/:id"
                    exact
                    component={(route) => (
                      <TemplateEdit
                        route={route}
                        history={history}
                        context={context}
                      />
                    )}
                  />
                </Switch>
              </section>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
}
