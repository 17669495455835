import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";

import CommunicationActivityRoutes from "./routes/CommunicationActivityRoutes";
import NegotiationActivityRoutes from "./routes/NegotiationActivityRoutes";
import SalesActivityRoutes from "./routes/SalesActivityRoutes";
import { IsModuleEnabled } from "./services/Permissions";
import RouteIndexer from "./pages/RouteIndexer";
import GoIntegratorActivityRoutes from "./routes/GoIntegratorRoutes";
import EmailTemplatesRoute from "./routes/EmailTemplatesRoute";
import CallScriptsRoutes from "./routes/CallScriptsRoutes";
import ListCreatorRoutes from "./routes/ListCreatorRoutes";
import EmailTagIndex from "./pages/settings/emailTags/index";
import EmailTagCreate from "./pages/settings/emailTags/create";

export default function Root(props) {
  let context = { ...props };

  //This root component will hold prefixes to other root groups

  const moduleAccess = {
    communication: IsModuleEnabled(["leads", "tickets", "activities"], context),
    sales: IsModuleEnabled(["leads", "tickets"], context),
    negotiation: IsModuleEnabled(["leads", "tickets"], context),
    "go-integrator": IsModuleEnabled(["leads", "tickets"], context),
    marketing: IsModuleEnabled(
      ["call-scripts", "email-templates", "list-creator"],
      context
    ),
  };

  console.log( moduleAccess.marketing );

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route
                      path="/activities/index"
                      exact
                      component={(route) => (
                        <RouteIndexer
                          context={context}
                          route={route}
                          moduleAccess={moduleAccess}
                        />
                      )}
                    />

                    {moduleAccess.marketing && (
                      <Route
                        path="/email-templates"
                        component={(route) => (
                          <EmailTemplatesRoute
                            context={context}
                            route={route}
                          />
                        )}
                      />
                    )}

                    {moduleAccess.marketing && (
                      <Route
                        path="/call-scripts"
                        component={(route) => (
                          <CallScriptsRoutes context={context} route={route} />
                        )}
                      />
                    )}

                    {moduleAccess.marketing && (
                      <Route
                        path="/list-creator"
                        component={(route) => (
                          <ListCreatorRoutes context={context} route={route} />
                        )}
                      />
                    )}

                    {moduleAccess.communication && (
                      <>
                        <Route
                          path="/activities/communication"
                          component={(route) => (
                            <CommunicationActivityRoutes
                              context={context}
                              route={route}
                            />
                          )}
                        />
                        <Route
                        path="/activities/emailTag/index"
                        exact
                        component={(route) => (
                          <EmailTagIndex
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                      <Route
                        path="/activities/emailTag/create"
                        exact
                        component={(route) => (
                          <EmailTagCreate
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    </>
                    )}
                    {moduleAccess.negotiation && (
                      <Route
                        path="/activities/negotiation"
                        component={(route) => (
                          <NegotiationActivityRoutes
                            context={context}
                            route={route}
                          />
                        )}
                      />
                    )}

                    {moduleAccess.sales && (
                      <Route
                        path="/activities/sales"
                        component={(route) => (
                          <SalesActivityRoutes
                            context={context}
                            route={route}
                          />
                        )}
                      />
                    )}

                    {moduleAccess["go-integrator"] && (
                      <Route
                        path="/activities/go-integrator"
                        component={(route) => (
                          <GoIntegratorActivityRoutes
                            context={context}
                            route={route}
                          />
                        )}
                      />
                    )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
