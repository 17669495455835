import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Done";
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  CircularProgress,
  Snackbar,
  IconButton,
  LinearProgress,
} from "@material-ui/core";

import {
  ActivityApi,
  ClientsApi,
  DynamicListApi,
  CampaignApi,
  TargetsApi,
} from "@unity/components";
import AppButton from "../../../common/AppButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CallBoxTargets({
  open,
  data,
  title,
  handleCancel,
  stage,
  setStage,
  targets,
  active,
  number,
  script,
  handleSelectedClient,
  nextCall,
  goBack,
  currentData,
  callActions,
  setCurrentData,
  setActive,
  listType,
  setNumber,
  handleSaveAsNewActivity,
  handleClose,
}) {
  const [completeAlert, setCompleteAlert] = useState(false);

  const handleUnsub = async (id) => {
    const res = await TargetsApi.unsubscribe(id);

    console.log("Unsub res", res);

    alert("Unsubscribed");
  };

  const handleView = () => {
    window.open(`/${listType}/view/${targets[active].id}`, "_blank");

    //'${listType}/view/${targets[active].customer_id}'
  };

  const saveCall = async () => {
    const savedData = {
      model: "Contact-Service/Customer",
      id: targets[active].customer_id,
      action: "Customer Was Called",
      description: `Notes: ${currentData.notes}, Action: ${currentData.action}`,
    };
    const res = await ActivityApi.saveLog(savedData);

    if (active + 1 <= targets.length - 1) {
      setActive(active + 1);
      setNumber(false);
      setStage(1);
      setCurrentData({});
      // setSelectedClient({});
      var notesField = document.getElementById("notesField");
      notesField.value = "";
      return;
    }

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
  };

  const convertResponse = async () => {
    const obj = {
      campaign_id: data.association,
      client_id: targets[active].id,
      subject: "Call List Response",
      body: currentData.notes,
      type: "Phone",
      customer_type: 1,
    };

    const res = await CampaignApi.saveResponse(obj);

    console.log("Convert to res", res);

    const savedData = {
      model: "Contact-Service/Customer",
      id: targets[active].id,
      action: "Converted To a Response",
      description: `Notes: ${currentData.notes}, Action: ${currentData.action}`,
    };
    const res2 = await ActivityApi.saveLog(savedData);

    if (active + 1 === targets.length) {
      setCompleteAlert(true);
    }
    nextCall();
  };

  const hideCompleteAlert = () => {
    setCompleteAlert(false);

    handleCancel(active, data);
  };

  const changeActive = () => {
    if (targets.length > 0) {
      setActive(targets.length - 1);
    } else {
      alert(
        "Your list is now empty. All targets have been removed from it. Please check your target list"
      );
    }
  };

  return (
    <div style={{ width: "800px!important" }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCancel(active, data)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ maxWidth: "800px!important", minWidth: "200px!important" }}
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        {targets &&
        targets.length > 0 &&
        targets[active] !== undefined &&
        active !== undefined ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-2">
                {active + 1 + " / " + targets.length}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div
                    className="col-lg-12"
                    style={{ textAlign: "center", padding: 20 }}
                  >
                    <>
                      <img src={require("../../../assets/unity-group.png")} />

                      <p>{targets[active].company_name}</p>
                    </>

                    <TextField value={targets[active].contact_name} />
                  </div>
                  <div className="col-lg-12">
                    {stage === 1 && (
                      <>
                        {targets[active].contact_phone && (
                          <a href={`dial:${targets[active].contact_phone}`}>
                            <AppButton
                              btnIcon="phone"
                              text="Start Call"
                              style={{ background: "green", color: "white" }}
                              onClick={() => setStage(2)}
                            />
                          </a>
                        )}
                        <AppButton
                          btnIcon="arrow_back"
                          text="Go Back"
                          style={{
                            background: "blue",
                            color: "white",
                            float: "right",
                            marginRight: 5,
                          }}
                          onClick={() => goBack()}
                        />
                        <AppButton
                          btnIcon="skip_next"
                          text="Skip Call"
                          style={{
                            background: "red",
                            color: "white",
                            float: "right",
                          }}
                          onClick={() => nextCall()}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <TextField
                      id="notesField"
                      label="Notes"
                      name="notes"
                      value={targets[active].notes}
                      fullWidth
                      onChange={(e) => {
                        setCurrentData({
                          ...currentData,
                          [e.target.name]: e.target.value,
                        });
                        targets[active].notes = e.target.value;
                      }}
                      multiline
                    />
                  </div>
                  {stage === 2 && (
                    <>
                      <div className="row">
                        <div className="col-lg-12">
                          <InputLabel
                            style={{
                              marginLeft: 13,
                              paddingTop: 10,
                              width: "100%",
                            }}
                          >
                            Call Result
                          </InputLabel>

                          <Select
                            name="action"
                            fullWidth
                            onChange={(e) => {
                              setCurrentData({
                                ...currentData,
                                action: e.target.value.id,
                              });

                              targets[active].action = e.target.value.id;
                              targets[active].action_name = e.target.value.name;
                            }}
                            value={currentData.action}
                            style={{ width: "100%", marginLeft: 13 }}
                          >
                            {callActions.map((item, key) => {
                              return (
                                <MenuItem key={key} value={item}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-4">
                          <AppButton
                            btnIcon="skip_next"
                            text="Next Call"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => saveCall()}
                          />
                        </div>
                        <div className="col-lg-4">
                          <AppButton
                            btnIcon="skip_next"
                            text="Convert to Response"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => convertResponse()}
                          />
                        </div>
                        <div className="col-lg-4">
                          <AppButton
                            btnIcon="save"
                            text="save as new activity"
                            style={{
                              background: "green",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() =>
                              handleSaveAsNewActivity(targets[active])
                            }
                          />
                        </div>

                        <div className="form-group col-lg-4">
                          <AppButton
                            btnIcon="arrow_back"
                            text="Go Back"
                            style={{
                              background: "blue",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => goBack()}
                          />
                        </div>

                        <div className="form-group col-lg-4">
                          <AppButton
                            btnIcon="skip"
                            text="view history"
                            style={{
                              background: "blue",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => handleView()}
                          />
                        </div>

                        <div className="form-group col-lg-4">
                          <AppButton
                            btnIcon="phone_disabled_icon"
                            text="Unsubscribe"
                            style={{
                              background: "red",
                              color: "white",
                              marginTop: 10,
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            onClick={() => handleUnsub(targets[active].id)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <p>{script}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            {targets && targets.length <= 0 ? (
              alert(
                "List is empty or contains no valid targets. Please review your tagets and their attached data"
              )
            ) : (
              <>
                {targets &&
                targets[active] === undefined &&
                active !== undefined ? (
                  <div style={{ textAlign: "center" }}>
                    <p>
                      <WarningIcon style={{ color: "red", fontSize: "32pt" }} />
                    </p>
                    <p>
                      It appears that targets have been removed from your bulk
                      call list.
                    </p>
                    <p>
                      <i>
                        This may have occured due to a range of reasons from:
                        duplicate culling, manual deletion, conversion to lead
                        or a change in list query parameters
                      </i>
                    </p>
                    <p>
                      You may either create a new bulk call activity or resume
                      this list from the last available target
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeActive();
                      }}
                    >
                      Last Target in List
                    </Button>
                  </div>
                ) : (
                  <>
                    <CircularProgress />
                    <p>Loading</p>
                  </>
                )}
              </>
            )}
          </div>
        )}
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel(active, data)} color="primary">
            Close
          </Button>
        </DialogActions>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={completeAlert}
          autoHideDuration={6000}
          onClose={hideCompleteAlert}
          message="All Calls Completed!"
          style={{
            background: "green",
            color: "white",
          }}
          action={
            <IconButton
              size="small"
              color="inherit"
              onClick={hideCompleteAlert}
            >
              <DoneIcon style={{ color: "#4CA64C" }} fontSize="small" />
            </IconButton>
          }
        />
      </Dialog>
    </div>
  );
}
