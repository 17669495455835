import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Icon } from "@material-ui/core";
import FilterTable from "../../common/FilterTable";
import FileBase64 from "react-file-base64";
import { AppButtonComponent, DocumentVaultAPI } from "@unity/components";

export default function MediaLibary(props) {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessge, setSnackMessage] = useState("");
  const [showUpload, setShowUpload] = useState("none");
  const [uploadData, setUploadData] = useState({});
  const [uploading, setUploading] = useState(false);

  const handleLinkCopy = async (docId) => {
    const res = await DocumentVaultAPI.generateFixedLink(docId);
    try {
      await navigator.clipboard.writeText(res.data);
      setSnackMessage("Link Copied");
      setSnackOpen(true);
    } catch (e) {
      setSnackMessage(
        "Failed to Automatically Copy. Please Manually Copy this Link: " +
          res.data
      );
      setSnackOpen(true);
    }

    props.onClose();
  };

  const handleUpload = async (e) => {
    setUploadData({
      application_ref: props.context.auth.app_uuid,
      documents: [e],
      public: 1,
    });
  };

  const handleStore = async () => {
    setUploading(true);
    if (typeof uploadData.documents === "undefined") {
      setSnackMessage("Please Select a File First");
      setSnackOpen(true);
      setUploading(false);
      return;
    }
    await DocumentVaultAPI.storeDocument(uploadData);

    setSnackMessage("Upload Finished");
    setSnackOpen(true);
    setUploading(false);

    setShowUpload("none");
    setUploadData({});

    const res = await DocumentVaultAPI.documentsIndex(1);
    props.data = res.data;
  };

  const handleView = async (docId) => {
    const res = await DocumentVaultAPI.generateTempLink(docId);
    window.open(res.data, "_blank");
  };

  const columns = [
    { name: "id", label: "Document ID" },
    { name: "name", label: "File Name" },
    { name: "size", label: "File Size" },
    { name: "raw_type", label: "File Type" },
    { name: "created_by_name", label: "Created By" },
    { name: "created_at", label: "Created At" },
    {
      name: "",
      label: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <AppButtonComponent
                text="Copy Link"
                btnIcon="content_copy"
                style={{ "margin-left": "10px" }}
                onClick={() => {
                  handleLinkCopy(data[dataIndex].id);
                }}
              />
              <AppButtonComponent
                text="View"
                btnIcon="download"
                style={{ "margin-left": "10px" }}
                onClick={() => {
                  handleView(data[dataIndex].id);
                }}
              />
            </div>
          );
        },
      },
    },
  ];
  const options = {};
  const data = props.data;

  return (
    <div id="media-library">
      <Snackbar
        open={snackOpen}
        message={snackMessge}
        onClose={() => {
          setSnackOpen(false);
          setSnackMessage("");
        }}
        autoHideDuration={6000}
      />
      <Dialog
        fullWidth
        maxWidth="xl"
        open={props.open}
        onClose={props.onClose}
        scroll={"paper"}
        style={{ padding: "15px" }}
      >
        <DialogTitle
          style={{
            background: props.context.theme.sidebar.background,
            color: "white",
          }}
        >
          <IconButton
            onClick={() => {
              props.onClose();
            }}
            color="inherit"
          >
            <Icon>close</Icon>
          </IconButton>
          Media Library
        </DialogTitle>
        <div style={{ padding: "15px" }}>
          <div
            style={{
              display: showUpload === "none" ? "block" : "none",
              "margin-bottom": "10px",
            }}
          >
            <AppButtonComponent
              text="Upload File"
              btnIcon="upload"
              onClick={() => {
                setShowUpload("block");
              }}
            />
          </div>
          <div id="uploadForm" style={{ display: showUpload }}>
            <div className="form-row">
              <AppButtonComponent
                text="Cancel Upload"
                btnIcon="cancel"
                onClick={() => {
                  setShowUpload("none");
                }}
              />
            </div>
            <hr />
            <div className="form-row">
              <p
                style={{
                  color: "red",
                  "font-weight": "bold",
                  "padding-left": "15px",
                }}
              >
                WARNING: All Files Uploaded to the Media Library Are Publicly
                Available. Do Not Upload Anything You Do Not Wish To Be Accessed
                By Everyone.
              </p>
            </div>
            <div className="form-row">
              <div className="form-group col-lg-4">
                <FileBase64 multiple={false} onDone={(e) => handleUpload(e)} />
                <i>Max Size: 5MB</i>
              </div>
              <div className="form-group col-lg-4">
                <Button
                  disabled={uploading}
                  variant="contained"
                  onClick={() => {
                    handleStore();
                  }}
                  style={{
                    background: props.context.theme.sidebar.background,
                    color: "white",
                  }}
                >
                  {uploading ? (
                    <CircularProgress color="success" size={24} />
                  ) : (
                    <Icon>upload</Icon>
                  )}{" "}
                  Upload
                </Button>
              </div>
            </div>
            <hr />
          </div>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </Dialog>
    </div>
  );
}
