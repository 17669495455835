import React from "react";

import OnBoardingActivityPage from "../../modules/activities/pages/sales/OnBoardingActivityPage";
import AfterSalesActivityPage from "../../modules/activities/pages/sales/AfterSalesActivityPage";

export default function SalesActivityEdit({ route, context }) {
  const forms = {
    "on-boarding": OnBoardingActivityPage,
    "after-sales": AfterSalesActivityPage,
  };

  const RenderForm = forms[route.match.params.activity_type];

  return (
    <>
      <RenderForm type="edit" route={route} context={context} />
    </>
  );
}
