import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "../services/AuthContext";
import SalesActivitiesView from "../pages/sales/view";
import SalesActivitiesCreate from "../pages/sales/create";
import SalesActivitiesEdit from "../pages/sales/edit";
import SalesActivitiesIndex from "../pages/sales/activityIndex";
import CustomerIndexer from "../pages/common/customerIndex";
import SalesTypeIndex from "../pages/sales/typeIndex";
import CustomerIndex from "../pages/common";
export default function SalesActivityRoutes(props) {

  const context = props.context;
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route
                      path="/activities/sales/index"
                      exact
                      component={(route) => (
                        <CustomerIndexer
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />
                    <Route
                      path="/activities/sales/:customer_type/index"
                      exact
                      component={(route) => (
                        <CustomerIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/sales/:customer_type/:customer_id/index"
                      exact
                      component={(route) => (
                        <SalesTypeIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/sales/:customer_type/:customer_id/:activity_type/index"
                      exact
                      component={(route) => (
                        <SalesActivitiesIndex
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />



                    <Route
                      path="/activities/sales/:customer_type/:customer_id/:activity_type/view/:activity_id"
                      exact
                      component={(route) => (
                        <SalesActivitiesView
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />

                    <Route
                      path="/activities/sales/:customer_type/:customer_id/:activity_type/edit/:activity_id"
                      exact
                      component={(route) => (
                        <SalesActivitiesEdit
                          route={route}
                          context={context}
                          history={history}
                        />
                      )}
                    />

                    <Route
                      path="/activities/sales/:customer_type/:customer_id/:activity_type/create"
                      exact
                      component={(route) => (
                        <SalesActivitiesCreate
                          route={route}
                          history={history}
                          context={context}
                        />
                      )}
                    />
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
