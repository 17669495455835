import { Button, CircularProgress, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import React, { useState, useRef, useEffect } from "react";
import EmailEditor from "react-email-editor";
import TemplateKey from "./TemplateKey";
import "./TemplateStyle.css";

export default function MailEditor(props) {
  const editorRef = useRef(null);
  const loadedRef = useRef(null);
  const [editorID, setEditorID] = useState(Math.floor(Math.random() * 1000 + 1).toString());
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    if (props.type === "edit" || props.type === "view") {
      setTimeout(function () {
        editorRef.current.editor.loadDesign(props.jsonTemplate);
      }, 5000);
    }
  };

  const onReady = () => {
    loadedRef.current = editorRef.current;
    setLoading(false);
  };

  const localSave = () => {
    loadedRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      props.handleSave(design, html);
    });
  };

  const generateEditorID = () => {
    var rand = Math.floor(Math.random() * 1000 + 1);
    setEditorID(rand.toString());
  };

  useEffect(() => {
    generateEditorID();
  }, []);

  return editorID !== null ? (
    ((props.type === "edit" || props.type === "view") && props.jsonTemplate) ||
    props.type === undefined ? (
      <div>
        <div>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={6}>
              <TemplateKey />
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={loading}
                variant="contained"
                color="success"
                onClick={() => {
                  localSave();
                }}
                startIcon={<SaveIcon />}
              >
                {loading ? "Loading Editor - Please Wait..." : "Save Template"}
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="editor">
          <EmailEditor
            ref={editorRef}
            onLoad={onLoad}
            onReady={onReady}
            editorId={editorID}
            className={editorID}
          />
        </div>
      </div>
    ) : (
      <>
        <CircularProgress />
        <p> Loading Template...</p>
      </>
    )
  ) : (
    <>
      <CircularProgress />
      <p>Generating Template Builder...</p>
    </>
  );
}
