import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Loading from "../../common/Loading";
import { LeadsApi, AppHeaderComponent, AppButtonComponent, CallApi } from "@unity/components";
import FilterTable from "../../common/FilterTable";
import { Icon } from "@material-ui/core"

export default function GoIntegratorCustomerLogs({ route, context }) {
    const [data, setData] = useState([])

    const getAllActivities = async () => {
        const res = await CallApi.getCustomerLogs(route.match.params.customer_id);
        setData(res.data);
    }

    var months = [
        "January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "Decemeber"
    ];

    var days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const formatDate = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + 1 + ':' + minutes + ' ' + ampm;
        return days[date.getDay() - 1] + " " + months[date.getMonth()] + " " + date.getDate() + " " + date.getFullYear() + " " + strTime;
    }

    const columns = [
        {
            name: "", label: "From", options: {
                customBodyRenderLite: (dataIndex) => {
                    const split = data[dataIndex].description.split("[")
                    const name = split[1].split("]")

                    return (
                        name[0]
                    )
                }
            }
        },
        {
            name: "", label: "To", options: {
                customBodyRenderLite: (dataIndex) => {
                    const split = data[dataIndex].description.split("[")
                    const name = split[2].split("]")
                    return (
                        name[0]
                    )
                }
            }
        },
        {
            name: "", label: "Type (Inbound / Outbound)", options: {
                customBodyRenderLite: (dataIndex) => {
                    if (data[dataIndex].inbound) {
                        return (
                            <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
                                <Icon style={{ color: 'green' }}>trending_down</Icon>
                                <span style={{ fontWeight: 'bold', marginLeft: 10 }}>Inbound</span>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
                                <Icon style={{ color: 'red' }}>trending_up</Icon>
                                <span style={{ fontWeight: 'bold', marginLeft: 10 }}>Outbound</span>
                            </div>

                        )
                    }
                }
            }
        },
        {
            name: "", label: "External Number", options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
                            <Icon>phone</Icon>
                            <span style={{ fontWeight: 'bold', marginLeft: 10 }}>{data[dataIndex].external_no}</span>
                        </div>
                    )
                }
            }
        },
        {
            name: "", label: "Duration", options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
                            <Icon>access_alarm</Icon>
                            <span style={{ fontWeight: 'bold', marginLeft: 10 }}>{data[dataIndex].duration}s</span>
                        </div>
                    )
                }
            }
        },
        {
            name: "", label: "Date / Time", options: {
                customBodyRenderLite: (dataIndex) => {
                    var res = data[dataIndex].start;
                    var d = new Date(res);
                    var date = formatDate(d);
                    return (
                        <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
                            <Icon>schedule</Icon>
                            <span style={{ fontWeight: 'bold', marginLeft: 10 }}>{date}</span>
                        </div>
                    )
                }
            }
        },
    ]

    useEffect(() => {
        getAllActivities()
    }, []);


    return (
        <>
            <AppHeaderComponent context={context} theme={context.theme} name="activities" subpage="go-integrator" subpage2="index" />
            <div style={{ paddingTop: 160 }}>
                <FilterTable data={data} columns={columns} />
            </div>
        </>
    );
}